<div class="modal-content">
  <div class="fsop-alert-modal">
    <span>{{ content.title }}</span>
  </div>

  <div class="fsop-alert-modal-body">
    <p
      *ngIf="content.message"
      class="mb-0"
      [ngClass]="
        showCSVUploader || showBlacklistedEmailCSVUploader
          ? 'extra-spacing pb-2'
          : showDeleteBackup || checkResponse
          ? 'text-left'
          : ''
      "
      [innerHtml]="content.message"
    ></p>

    <ng-container *ngIf="showUpdateexpirationButton">
      <div class="row">
        <div class="col-md-6">
          <!-- <label class="embedLabel" >
              <span class="radio-options">Date:</span>
            </label> -->
          <input
            (click)="d.toggle()"
            [(ngModel)]="model"
            (ngModelChange)="modalValueChanged($event)"
            class="form-control"
            placeholder="mm-dd-yyyy"
            name="dp"
            id="date_picker_format"
            ngbDatepicker
            onkeydown="return false"
            #d="ngbDatepicker"
            [minDate]="minDate"
          />
        </div>

        <div class="col-md-6 alertTimePicker">
          <!-- <label class="embedLabel">
              <span class="radio-options">Time:</span>
            </label> -->
          <!-- <ngb-timepicker [(ngModel)]="time" [meridian]="true"></ngb-timepicker> -->
          <ng-template #popTemplate>
            <ngb-timepicker
              [(ngModel)]="myExpireTime"
              (ngModelChange)="scheduleExpireTimeChanged($event)"
              [meridian]="true"
            ></ngb-timepicker>
          </ng-template>
          <input
            [(ngModel)]="scheduleExpireTimeVal"
            id="schedule_time"
            container=".modal-content"
            [ngbPopover]="popTemplate"
            [autoClose]="'outside'"
            onkeydown="return false"
            placement="bottom"
            class="form-control"
            [readonly]="true"
            (click)="updateMyExpireTime()"
          />
        </div>
        <small
          class="col-sm-12 text-muted mt-2"
          *ngIf="content.data?.timezoneNote || timezoneNote"
          style="margin-left: -130px;"
        >
          {{ content.data.timezoneNote ? content.data.timezoneNote :
          timezoneNote }}
        </small>
      </div>
    </ng-container>

    <!-- launch survey work -->
    <div class="form-group mb-0" *ngIf="showScheduleSurvey">
      <div class="d-flex align-item-center mb-3">
        <ng-container *ngIf="showScheduleOptions">
          <div class="radio-buttons me-3">
            <label
              class="embedLabel d-flex align-item-center"
              [ngClass]="{ active: !isScheduleLater }"
            >
              <input
                type="radio"
                class="fsop-radio"
                value="schedule_launch"
                checked
                name="schedule_launch"
                (change)="scheduleNow($event)"
              />
              <span class="radio-options"> Launch Now</span>
            </label>
          </div>
        </ng-container>
        <ng-container *ngIf="showScheduleOptions">
          <div class="radio-buttons">
            <label
              class="embedLabel d-flex align-item-center"
              [ngClass]="{ active: isScheduleLater }"
            >
              <input
                type="radio"
                class="fsop-radio"
                value="schedule_launch"
                name="schedule_launch"
                (change)="scheduleLater($event)"
              />
              <span class="radio-options">Launch Later</span>
            </label>
          </div>
        </ng-container>
      </div>
      <ng-container>
        <div class="dates-row" [ngClass]="{ disabled: !isScheduleLater }">
          <div class="row">
            <div class="col-md-6">
              <label class="embedLabel" *ngIf="!showScheduleOptions">
                <span class="radio-options">Launch Date:</span>
              </label>
              <input
                (click)="d.toggle()"
                [(ngModel)]="schedule_date_model"
                (ngModelChange)="scheduleDateChanged($event)"
                class="form-control"
                placeholder="mm-dd-yyyy"
                name="dp"
                id="schedule_date"
                ngbDatepicker
                onkeydown="return false"
                #d="ngbDatepicker"
                [readonly]="true"
                [minDate]="minDateSchedule"
                [disabled]="!isScheduleLater"
              />
            </div>
            <div class="col-md-6 alertTimePicker">
              <label class="embedLabel" *ngIf="!showScheduleOptions">
                <span class="radio-options">Launch Time:</span>
              </label>
              <!-- <ngb-timepicker [(ngModel)]="time" [meridian]="true"></ngb-timepicker> -->
              <ng-template #popTemplate>
                <ngb-timepicker
                  [(ngModel)]="mytime"
                  (ngModelChange)="scheduleTimeChanged($event)"
                  [meridian]="true"
                ></ngb-timepicker>
              </ng-template>
              <input
                [(ngModel)]="scheduleTimeVal"
                id="schedule_time"
                container=".modal-content"
                [ngbPopover]="popTemplate"
                [autoClose]="'outside'"
                onkeydown="return false"
                placement="bottom"
                class="form-control"
                [readonly]="true"
                (click)="updateMyTime()"
                [disabled]="!isScheduleLater"
              />
            </div>
            <small
              class="col-sm-12 text-muted mt-2"
              *ngIf="content.data?.survey?.timezoneNote"
            >
              {{ content.data.survey.timezoneNote }}
            </small>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="form-group" *ngIf="showCreateSurvey">
      <label class="color-black" *ngIf="content.data?.copyUsingProject;else surveyCopyTitle">Give your project a name:</label>
      <ng-template #surveyCopyTitle>
        <label class="color-black">Give your survey a name:</label>

      </ng-template>
      <input
        maxlength="80"
        (keydown)="ifEnterSubmit($event, surveyTitle)"
        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        [(ngModel)]="surveyTitle"
        type="text"
        name="survey-name"
        class="form-control"
      />
      <div *ngIf="ifNoValue(surveyTitle)" class="is_required mt-1">
        This field is required
      </div>

      <div class="mt-2" *ngIf="isCopy">
        Company: {{ content.data.surveyData ?
        content.data.surveyData.company__name : "" }}
        <span>
          <a
            (click)="openCompanyInfo()"
            class="table-app-edit ms-1"
            style="vertical-align: middle; transform: scale(0.7);"
            title="Edit"
            >Edit</a
          >
        </span>
      </div>
    </div>

    <div class="form-group" *ngIf="showEditCompany">
      <label class="color-black">Enter Company name:</label>
      <input
        maxlength="80"
        (keydown)="ifEnterClicked($event, companyTitle)"
        [(ngModel)]="companyTitle"
        type="text"
        name="company-name"
        class="form-control"
      />
    </div>

    <div class="form-group" *ngIf="showCreateColumn">
      <label
        class="color-black"
        [ngbTooltip]="UploaddeactivateContent"
        placement="right"
        >Give your column a unique name:</label
      >
      <input
        maxlength="30"
        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        [(ngModel)]="columnName"
        type="text"
        name="column-name"
        class="form-control"
      />
      <div *ngIf="ifNoValue(columnName)" class="is_required">
        This field is required
      </div>

      <div
        *ngIf="!ifNoValue(columnName) && !isUniqueColumn(columnName)"
        class="is_required"
      >
        The column name should be unique
      </div>

      <div
        *ngIf="!ifNoValue(columnName) && isRestritedColumn(columnName)"
        class="is_required"
      >
        The column name is restricted
      </div>
    </div>

    <ng-template #UploaddeactivateContent>
      <div>
        Restricted Column names are: email, first_name, last_name, role, id,
        status
      </div>
    </ng-template>

    <div class="form-group" *ngIf="checkResponse">
      <div *ngFor="let item of content.data.options">
        <input
          [(ngModel)]="selectedOptionType"
          type="radio"
          [value]="item.text"
          style="margin-right: 5px;"
          name="optionName"
        />
        <label class="color-black">{{ item.text }}</label>
      </div>
    </div>
    <div class="form-group" *ngIf="showPolicy">
      <div>
        <input
          type="checkbox"
          [checked]="isShowPolicy"
          class="fsop-checkbox"
          (change)="this.isShowPolicy = !isShowPolicy"
          style="margin-right: 5px;"
          name="optionName"
        />
        <label style="margin-left: 5px;" class="color-black"
          >I agree to the Ambivista Terms
          <a href="https://www.ambivista.com/terms-of-use/" target="_blank"
            >Terms of Use</a
          >
          and
          <a href="https://www.ambivista.com/privacy-policy/" target="_blank"
            >Privacy Policy</a
          ></label
        >
      </div>
    </div>

    <div class="form-group" *ngIf="showCopySurveyTemplate">
      <label>Give your survey tempate a name:</label>
      <input
        maxlength="80"
        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        [(ngModel)]="copyTemplateTitle"
        type="text"
        name="copy-template-title"
        class="form-control"
      />
      <small *ngIf="ifNoValue(copyTemplateTitle)" class="required">
        {{ requiredErrMsg }}
      </small>
    </div>

    <div class="form-group" *ngIf="showCreateCompany">
      <label>Company Name:</label>
      <input
        maxlength="70"
        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        [(ngModel)]="companyName"
        type="text"
        name="company-name"
        class="form-control"
      />
      <div *ngIf="companyName && companyName.length">
        <small
          >Character Count: {{ companyName && companyName.length ?
          companyName.length : 0 }}
        </small>
      </div>
      <small *ngIf="ifNoValue(companyName)" class="required">
        {{ requiredErrMsg }}
      </small>
      <small class="required" *ngIf="companyName && companyName.length > 50"
        >You can only input a maximum of 70 characters.</small
      >
    </div>

    <div class="form-group" *ngIf="showCreateCompanyViaCopySurvey">
      <label>Company Name:</label>
      <input
        maxlength="70"
        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        [(ngModel)]="companyName"
        type="text"
        name="company-name"
        class="form-control"
      />
      <div *ngIf="companyName && companyName.length">
        <small
          >Character Count: {{ companyName && companyName.length ?
          companyName.length : 0 }}
        </small>
      </div>
      <small *ngIf="ifNoValue(companyName)" class="required">
        {{ requiredErrMsg }}
      </small>
      <small class="required" *ngIf="companyName && companyName.length > 50"
        >You can only input a maximum of 70 characters.</small
      >
    </div>

    <div class="form-group" *ngIf="showSaveAsTemplate">
      <label>Template Name: <span class="required">*</span> </label>
      <input
        maxlength="80"
        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        [(ngModel)]="templateName"
        type="text"
        name="template-name"
        class="form-control"
      />
      <small *ngIf="ifNoValue(templateName)" class="required">
        {{ requiredErrMsg }}
      </small>
    </div>

    <div *ngIf="showShareSurvey" class="row flexi">
      <div
        class="col-md-3 bxb"
        [ngClass]="{ 'disable-div': scopeOfSurvey == 0 }"
      >
        <div>
          <h3><i class="fa fa-envelope" aria-hidden="true"></i>Email</h3>
          <h6>
            <a
              style="cursor: pointer;"
              (click)="goToShareByEmail(content.data['survey'])"
            >
              Distribute your project via email
            </a>
          </h6>
        </div>
      </div>
      <div
        class="col-md-3 bxb"
        [ngClass]="{
          'disable-div':
            content.data['survey']['survey_type'] == 'Multirater Survey' ||
            content.data['survey']['survey_type'] == 1 ||
            content.data['survey']['survey_type_code'] == 1
        }"
      >
        <div [ngClass]="{ 'disable-div': scopeOfSurvey == 1 }">
          <h3><i class="fa fa-globe" aria-hidden="true"></i>Open Web</h3>

          <h6>
            <a
              ngxClipboard
              [cbContent]="sharingUrl"
              (click)="copyLink(content.data['survey'])"
              style="cursor: pointer;"
              >Create an open link to your project</a
            >
          </h6>
        </div>
      </div>
      <div
        class="col-md-3 bxb"
        [ngClass]="{
          'disable-div':
            content.data['survey']['survey_type'] == 'Multirater Survey' ||
            content.data['survey']['survey_type'] == 1 ||
            content.data['survey']['survey_type_code'] == 1
        }"
      >
        <div [ngClass]="{ 'disable-div': scopeOfSurvey == 1 }">
          <h3><i class="fa fa-share-alt" aria-hidden="true"></i>Social</h3>
          <div class="sharebuttons-container">
            <share-button
              (click)="shareWithSocialMedia(content.data['survey'])"
              button="facebook"
              [url]="sharingUrl"
              [showIcon]="true"
              [showText]="false"
            ></share-button>
            <share-button
              (click)="shareWithSocialMedia(content.data['survey'])"
              button="linkedin"
              [url]="sharingUrl"
              [showIcon]="true"
              [showText]="false"
            ></share-button>
            <share-button
              (click)="shareWithSocialMedia(content.data['survey'])"
              button="reddit"
              [url]="sharingUrl"
              [showIcon]="true"
              [showText]="false"
            ></share-button>
            <!-- <share-button (click)="shareWithSocialMedia(content.data['survey'])" button="google" [url]="sharingUrl" [showIcon]="true" [showText]="false"></share-button> -->
            <share-button
              (click)="shareWithSocialMedia(content.data['survey'])"
              button="twitter"
              [url]="sharingUrl"
              [showIcon]="true"
              [showText]="false"
            ></share-button>
            <!-- <a target="_blank" href="https://in.pinterest.com/pin/create/button/?url=http://webapp7-dev.ambivista.com/survey/s_400f0a02521611ea/resp&media=http://webapp7-dev.ambivista.com/assets/images/logo-with-bg.png&description=surveyfill" class="pin-it-button" count-layout="horizontal">
              <img border="0" src="//assets.pinterest.com/images/PinExt.png" title="Pin It" />
            </a>
            <share-button (click)="shareWithSocialMedia(content.data['survey'])" button="telegram" [url]="sharingUrl" [showIcon]="true" [showText]="false"></share-button>
            <share-button (click)="shareWithSocialMedia(content.data['survey'])" button="whatsapp" [url]="sharingUrl" [showIcon]="true" [showText]="false"></share-button>
            <share-button (click)="shareWithSocialMedia(content.data['survey'])" button="reddit" [url]="sharingUrl" [showIcon]="true" [showText]="false"></share-button>
            <share-button (click)="shareWithSocialMedia(content.data['survey'])" button="tumblr" [url]="sharingUrl" [showIcon]="true" [showText]="false"></share-button> -->
          </div>
        </div>
      </div>
      <div
        class="col-md-3 bxb"
        [ngClass]="{ 'disable-div': scopeOfSurvey == 1 }"
      >
        <div style="cursor: pointer;">
          <div>
            <h3>
              <i class="fa fa-code" aria-hidden="true"></i>Site Integrated
            </h3>
            <ul class="list-inline embed-icons">
              <li
                [title]="'Embed your survey on your site'"
                (click)="shareEmbedCode(content.data['survey'], 'Embed')"
              >
                <i class="svg site-embed"></i>
              </li>
              <li
                [title]="'Pop-up your survey on your site'"
                (click)="shareEmbedCode(content.data['survey'], 'SiteIntercept')"
              >
                <i class="svg site-intercept"></i>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="col-md-3 bxb"
        [ngClass]="{ 'disable-div': content.data['survey']['survey_type'] == 0 }"
      >
        <div style="cursor: pointer;">
          <div>
            <h3>
              <img class="portal-icon" src="assets/images/portal-icon.svg">Portal
            </h3>
            <ul class="list-inline embed-icons">
         
              <li
                [title]="'Create Portal'"
                (click)="goToShareByPortal(content.data['survey'])"
              >
                <i class="portal-image"></i>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showCSVUploader || showUploadNDButton" class="fileinput-sec">
      <div class="fileinput fileinput-new csv-file">
        <div *ngIf="uploadedFile">{{ uploadedFile }}</div>
        <div
          *ngIf="!uploadedFile"
          class="fileinput-preview thumbnail"
          style="overflow: hidden;"
          drag-drop-animation
          [defaultClass]="'thumbnail'"
          [onDragClass]="'thumbnail-drag'"
        >
          <input
            class="profile-image-file-input"
            name="file-input"
            type="file"
            accept=".csv"
            (change)="onFileChange($event)"
          />
        </div>
        <div
          *ngIf="uploadedFile"
          (click)="removeFile()"
          class="remove-btn remove-header-image"
        >
          <i class="fi fi-times"></i>
          Remove File
        </div>
      </div>
      <div *ngIf="response_building" class="progress-upload">
        Building Response...
      </div>
      <div *ngIf="nd_upload_err">
        <small class="required"> {{ nd_upload_err }} </small>
      </div>
    </div>

    <div *ngIf="showBlacklistedEmailCSVUploader" class="fileinput-sec">
      <div class="fileinput fileinput-new csv-file">
        <div *ngIf="uploadedFile">{{ uploadedFile }}</div>
        <div
          *ngIf="!uploadedFile"
          class="fileinput-preview thumbnail"
          style="overflow: hidden;"
          drag-drop-animation
          [defaultClass]="'thumbnail'"
          [onDragClass]="'thumbnail-drag'"
        >
          <input
            class="profile-image-file-input"
            name="file-input"
            type="file"
            accept=".csv"
            (change)="onFileChange($event)"
          />
        </div>
        <div
          *ngIf="uploadedFile"
          (click)="removeFile()"
          class="remove-btn remove-header-image"
        >
          <i class="fi fi-times"></i>
          Remove File
        </div>
      </div>
      <div *ngIf="response_building" class="progress-upload">
        Building Response...
      </div>
      <div *ngIf="nd_upload_err">
        <small class="required"> {{ nd_upload_err }} </small>
      </div>
    </div>

    <div *ngIf="showCSVUploader || showUploadNDButton" class="recommend-parent">
      <div class="recommend-block">
        <img src="assets/images/csv-sample.png" />
      </div>
      <div class="recommend-block2">
        <ul>
          <li>Recommended size: 2 MB (max)</li>
          <li>
            Download
            <a
              *ngIf="showCSVUploader"
              href="assets/images/SampleDrillDown.csv"
              download="sample.csv"
              class="sample-color"
              >sample CSV</a
            >
            <a
              *ngIf="showUploadNDButton"
              (click)="downloadSample()"
              href="javascript:void(0)"
              >sample CSV</a
            >
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf="showBlacklistedEmailCSVUploader" class="recommend-parent">
      <div class="recommend-block">
        <img src="assets/images/csv-sample.png" />
      </div>
      <div class="recommend-block2">
        <ul>
          <li>Recommended size: 2 MB (max)</li>
          <li>
            Download
            <a
              *ngIf="showBlacklistedEmailCSVUploader"
              href="assets/images/BlacklistEmails.csv"
              download="sample.csv"
              class="sample-color"
              >sample CSV</a
            >
          </li>
        </ul>
      </div>
    </div>
    <form
      [formGroup]="sectionForm"
      novalidate
      *ngIf="showAddNewSectionButton || showUpdateSectionButton"
    >
      <div class="group-input form-group row" style="align-items: inherit;">
        <label for="sectionName" class="col-sm-4 col-form-label"
          >Section Name:</label
        >
        <div class="col-sm-7">
          <input
            maxlength="200"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            formControlName="name"
            type="text"
            class="form-control"
            id="sectionName"
          />
          <div *ngIf="getSectionForm.name.value">
            <small
              >Character Count: {{ getSectionForm.name.value.length ?
              getSectionForm.name.value.length : 0 }}
            </small>
          </div>
          <span
            *ngIf="
              getSectionForm.name.errors &&
              (sectionForm.controls.name.touched || sectionForm.controls.name.dirty || sectionFormSubmitted)
            "
          >
            <small
              *ngIf="getSectionForm.name.errors.required"
              class="text-danger"
              translate
            >
              This field is required
            </small>
          </span>
          <small class="text-danger" *ngIf="ifMoreValue(getSectionForm.name)"
            >It is recommended to keep the totaI character count no more than 30
            to preserve report aesthetics.</small
          >
        </div>
        <div class="addon col-sm-1" style="margin-left: -10px;">
          <a
            class="btn btn-outline"
            (click)="showOptionalContainer = !showOptionalContainer"
            ><i class="fa fa-caret-down"></i
          ></a>
        </div>
      </div>
      <div class="form-group row" *ngIf="showOptionalContainer">
        <label for="sectionAbbrev" class="col-sm-4 col-form-label"
          >Section Abbreviation:</label
        >
        <div class="col-sm-7">
          <input
            maxlength="80"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            formControlName="abbreviation"
            type="text"
            class="form-control"
            id="sectionAbbrev"
          />
          <div *ngIf="getSectionForm.abbreviation.value">
            <small
              >Character Count: {{ getSectionForm.abbreviation.value.length ?
              getSectionForm.abbreviation.value.length : 0 }}
            </small>
          </div>
          <span
            *ngIf="
              getSectionForm.abbreviation.errors &&
              (sectionForm.controls.abbreviation.touched ||
                sectionForm.controls.abbreviation.dirty ||
                sectionFormSubmitted)
            "
          >
            <small
              *ngIf="getSectionForm.abbreviation.errors.required"
              class="text-danger"
              translate
            >
              This field is required
            </small>
          </span>
          <small
            class="text-danger"
            *ngIf="ifMoreValue(getSectionForm.abbreviation)"
            >It is recommended to keep the totaI character count no more than 30
            to preserve report aesthetics.</small
          >
        </div>
      </div>

      <div class="form-group row" *ngIf="showOptionalContainer">
        <label for="sectionDesc" class="col-sm-4 col-form-label"
          >Section Description:</label
        >
        <div class="col-sm-7">
          <textarea
            maxlength="300"
            formControlName="description"
            type="text"
            class="form-control"
            id="sectionDesc"
          >
          </textarea>
          <div *ngIf="getSectionForm.description.value">
            <small
              >Character Count: {{ getSectionForm.description.value.length ?
              getSectionForm.description.value.length : 0 }}
            </small>
          </div>

          <span
            *ngIf="
              getSectionForm.description.errors &&
              (sectionForm.controls.description.touched || sectionForm.controls.description.dirty)
            "
          >
            <small
              *ngIf="getSectionForm.description.errors"
              class="text-danger"
              translate
            >
              Maximum Word limit is 200.
            </small>
          </span>
        </div>
      </div>
    </form>
    <div
      class="row"
      *ngIf="this.content.positiveAction == 'deleteResponseUser'"
    >
      <div class="col-md-12 form-group">
        Are you sure you want to {{ content.data.type ? "deactivate" : "delete"
        }} {{ this.content.positiveAction == "deleteResponseUser" ? "selected
        responses" : "account" }}? If so, please type
        <strong>{{ content.data.type ? "DEACTIVATE" : "DELETE" }}</strong> in
        the text box below.
      </div>
      <div class="col-md-12 form-group">
        <input
          type="text"
          class="form-control confirm-delete"
          [(ngModel)]="confirm_box"
        />
        <ng-container *ngIf="!checkTypedWord(content.data.type)">
          <small class="required">
            In order to proceed with your request, please ensure that {{
            content.data.type ? "DEACTIVATE" : "DELETE" }} is entered in capital
            letters.
          </small>
        </ng-container>
      </div>
    </div>
  </div>
  <div
    class="row"
    style="margin-left: 0px;"
    *ngIf="showDeleteBackup && !content.data.toNotShowDelete"
  >
    <div class="col-md-12 form-group">
      Are you sure you want to {{ content.data.type ? "deactivate" : "delete" }}
      {{ this.content.positiveAction == "deleteResponseUser" ? "selected
      responses" : "account" }}? If so, please type
      <strong>{{ content.data.type ? "DEACTIVATE" : "DELETE" }}</strong> in the
      text box below.
    </div>
    <div class="col-md-12 form-group">
      <input
        type="text"
        class="form-control confirm-delete"
        [(ngModel)]="confirm_box"
      />
      <ng-container *ngIf="!checkTypedWord(content.data.type)">
        <small class="required">
          In order to proceed with your request, please ensure that {{
          content.data.type ? "DEACTIVATE" : "DELETE" }} is entered in capital
          letters.
        </small>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-footer">
  <!--<button *ngIf="showLaunchSurveyButton" (click)="launchSurvey()" class="btn btn-md btn-primary">Confirm</button>-->

  <button *ngIf="showSendEmail" (click)="sendEmail()" class="btn btn-primary">
    Send
  </button>

  <button *ngIf="showSaveEmail" (click)="saveEmail()" class="btn btn-primary">
    Save
  </button>

  <button
    *ngIf="showScheduleEmail"
    (click)="sendScheduleReminderToAll()"
    class="btn btn-primary"
  >
    Schedule
  </button>

  <button
    *ngIf="sendScheduleMultipleEmail"
    (click)="sendMultipleSchedule()"
    class="btn btn-primary"
  >
  Schedule
  </button>

  <button
    *ngIf="
      showDeactivateSurveyButton ||
      showLaunchSurveyButton ||
      showDeleteRaterButton ||
      showResetSheet ||
      showReloadRecords ||
      showDeleteRecords ||
      showDeleteSection ||
      showScaling ||
      checkResponse ||
      showConfirmButton ||
      showEditCompany ||
      this.content.positiveAction == 'editResponseUser'
    "
    (click)="confirm($event)"
    class="btn btn-primary"
  >
    Confirm
  </button>
  <button
    *ngIf="showDeleteBackup || this.content.positiveAction == 'deleteResponseUser'"
    (click)="confirm($event)"
    [disabled]="
      !(confirm_box && (confirm_box == 'DELETE' || confirm_box == 'DEACTIVATE')) && !content.data.toNotShowDelete
    "
    class="btn btn-primary"
  >
    Confirm
  </button>
  <button
    *ngIf="showScheduleSurvey && !showLaunchLater"
    (click)="confirm($event)"
    class="btn btn-primary"
  >
    Confirm
  </button>
  <button *ngIf="showLaunchLater" (click)="confirm($event)" class="btn btn-primary">
    Update
  </button>
  <button
    *ngIf="showPolicy"
    (click)="confirm($event)"
    [disabled]="!isShowPolicy"
    class="btn btn-primary"
  >
    Confirm
  </button>
  <button
    *ngIf="content.title === 'Update Survey Launch'"
    (click)="CancelSchedule()"
    class="btn btn-primary"
  >
    Unschedule
  </button>
  
  <!-- <button
    *ngIf="showSurveySchedule"
    (click)="confirm($event)"
    class="btn btn-primary"
  >
    Confirm
  </button> -->
  <button
    *ngIf="approvePolicyUpdate"
    (click)="confirm($event)"
    [disabled]="!approvePolicyUpdate"
    class="btn btn-primary"
  >
    Confirm
  </button>
  <button
    *ngIf="
      showDeleteRoleButton ||
      showDeleteUserButton ||
      showDeleteSUserButton ||
      showDeleteSurveyButton ||
      showDeleteNormButton ||
      showDeleteGroups ||
      showDeleteCompanyButton ||
      showDeleteColumnButton
    "
    (click)="delete()"
    class="btn btn-primary"
  >
    {{ showDeleteSurveyButton || showDeleteNormButton || showDeleteRoleButton ?
    "Confirm" : "Delete" }}
  </button>

  <button
    *ngIf="showUpdateexpirationButton"
    (click)="updateSurveyExpiration()"
    class="btn btn-primary"
  >
    Update
  </button>

  <button
    *ngIf="showCreateSurvey"
    (click)="!isCopy ? createSurvey() : !is_requestsent ? createSurvey() : ''"
    class="btn btn-primary"
    [disabled]="ifNoValue(surveyTitle)"
  >
    <span *ngIf="!isCopy">Create</span>
    <span *ngIf="isCopy">Copy</span>
  </button>

  <button
    *ngIf="showCreateColumn"
    (click)="createColumn()"
    class="btn btn-primary"
    [disabled]="ifNoValue(columnName) || isRestritedColumn(columnName) || !isUniqueColumn(columnName)"
  >
    <span>{{ this.content.data.col ? "Update" : "Add" }}</span>
  </button>
  <button
    *ngIf="showCopySurveyTemplate"
    (click)="copySurveyTemplate()"
    class="btn btn-primary"
    [disabled]="ifNoValue(copyTemplateTitle)"
  >
    Copy
  </button>

  <button
    [disabled]="checkIfName(companyName)"
    *ngIf="showCreateCompany"
    (click)="createCompany(companyName)"
    class="btn btn-primary"
  >
    <span>Create</span>
  </button>

  <button
    [disabled]="checkIfName(companyName)"
    *ngIf="showCreateCompanyViaCopySurvey"
    (click)="createCompany(companyName)"
    class="btn btn-primary"
  >
    <span>Create</span>
  </button>

  <button
    [disabled]="checkIfName(templateName)"
    *ngIf="showSaveAsTemplate"
    (click)="createCompany(templateName)"
    class="btn btn-primary"
  >
    <span>Save</span>
  </button>

  <button
    [disabled]="!uploadedFile"
    *ngIf="showCSVUploader || showUploadNDButton"
    (click)="onClickUpload()"
    class="btn btn-primary"
  >
    <span>Upload</span>
  </button>

  <button
    [disabled]="!uploadedFile"
    *ngIf="showBlacklistedEmailCSVUploader"
    (click)="onClickUpload()"
    class="btn btn-primary"
  >
    <span>Upload</span>
  </button>

  <button
    *ngIf="showUpdateSectionButton"
    (click)="updateSection()"
    class="btn btn-primary"
  >
    Save
  </button>
  <button
    *ngIf="showAddNewSectionButton"
    (click)="addNewSectionToSurvey()"
    class="btn btn-primary"
  >
    Add
  </button>
  <button
    *ngIf="showResendEmailButton"
    (click)="resendEmailToContact()"
    class="btn btn-primary"
  >
    Yes
  </button>

  <button
    *ngIf="!showBlacklistedEmailCSVUploader && !skipAnswer"
    (click)="cancel()"
    class="btn btn-secondary"
  >
    <span *ngIf="!showResendEmailButton"
      >{{ showCSVUploader ? "Close" : "Cancel" }}</span
    >
    <span *ngIf="showResendEmailButton">No</span>
  </button>

  <button
    *ngIf="!showCSVUploader && showBlacklistedEmailCSVUploader"
    (click)="cancel()"
    class="btn btn-secondary"
  >
    <span>{{ showBlacklistedEmailCSVUploader ? "Close" : "Cancel" }}</span>
  </button>
  <button
    class="btn btn-primary"
    (click)="canSkipAnswer(true)"
    *ngIf="skipAnswer"
  >
    Continue without Answering
  </button>
  <button
    class="btn btn-primary"
    (click)="canSkipAnswer(false)"
    *ngIf="skipAnswer"
  >
    Answer the Question
  </button>
</div>
<!-- </div> -->

<ng-template #expiryUpdateWarning let-modal>
  <div class="modal-content">
    <div class="confirm-modal-header">
      <h3>Alert</h3>
    </div>
    <div class="fsop-alert-modal-body">
      <p>
        This expiration date change will relaunch your survey{{
        content?.data?.surveyName ? ", " + content?.data?.surveyName : "" }}.
        Are you sure you want to continue?
      </p>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="clearExpiryWarningModal(1, null)">
        Confirm
      </button>
      <button class="btn btn-primary" (click)="clearExpiryWarningModal(0, null)">
        Cancel
      </button>
    </div>
  </div>
</ng-template>

<ng-template #embedCodeCopyPopup let-modal>
  <div class="modal-content embed_code_popup">
    <div class="confirm-modal-header">
      <button
        ngxClipboard
        [cbContent]="embedCode"
        class="btn btn-primary pull-right"
        (click)="copyEmbedCode()"
      >
        Copy
      </button>
    </div>
    <div class="fsop-alert-modal-body">
      <div class="form-group">
        <label for="embedded-code"></label>
        <textarea
          cols="30"
          rows="4"
          class="form-control wth-border embed_code"
          id="embedded-code"
          readonly
        >
{{
          embedCode
        }}</textarea
        >
      </div>
      <div>
        <div class="form-group">
          <div class="d-flex align-item-center mb-3">
            <div class="radio-buttons me-3">
              <label
                class="embedLabel d-flex align-item-center"
                [ngClass]="{ active: !activeEmbedRadio }"
              >
                <input
                  type="radio"
                  class="fsop-radio"
                  value="Embed"
                  checked
                  name="embedOption"
                  (change)="embedOptionChange($event,!activeEmbedRadio)"
                />
                <span class="radio-options"
                  >Embed your survey on your site</span
                >
              </label>
            </div>
            <div class="radio-buttons me-3">
              <label
                class="embedLabel d-flex align-item-center"
                [ngClass]="{ active: activeEmbedRadio }"
              >
                <input
                  type="radio"
                  class="fsop-radio"
                  value="SiteIntercept"
                  name="embedOption"
                  (change)="embedOptionChange($event,!activeEmbedRadio);"
                />
                <span class="radio-options"
                  >Pop-up your survey on your site</span
                >
              </label>
            </div>
          </div>
        </div>
        <div class="form-head text-left">
          <b>Embed Options:</b>
        </div>

        <div class="row mt-20">
          <div
            class="col-lg-12 display-inline-flex justify-content-center mb-10"
          >
            <div class="d-flex align-item-center me-15">
              <div class="me-10" style="min-width: 77px;">
                <b>Auto Height:</b>
              </div>
              <label class="switch-box mb-0">
                <input
                  type="checkbox"
                  [checked]="true"
                  (change)="checkBoxChecked($event, 'height')"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="d-flex align-item-center ms-15">
              <div class="me-10" style="min-width: 45px;">
                <b>Height:</b>
              </div>
              <div style="max-width: 129px;" class="input-group mb-0">
                <input
                  class="form-control wth-border txt-type"
                  (input)="getValue($event)"
                  id="embed_height"
                  disabled="true"
                  type="number"
                  [min]="100"
                />
                <div class="input-group-append">
                  <span class="input-group-text">px</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-12 display-inline-flex justify-content-center mb-10"
          >
            <div class="d-flex align-item-center me-15">
              <div class="me-10" style="min-width: 77px;">
                <b>Auto Width:</b>
              </div>
              <label class="switch-box mb-0">
                <input
                  type="checkbox"
                  [checked]="true"
                  (change)="checkBoxChecked($event, 'width')"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="d-flex align-item-center ms-15">
              <div class="me-10" style="min-width: 45px;">
                <b>Width:</b>
              </div>
              <div style="max-width: 129px;" class="input-group mb-0">
                <input
                  class="form-control wth-border txt-type"
                  (input)="getValue($event)"
                  id="embed_width"
                  disabled="true"
                  type="number"
                  [min]="100"
                />
                <div class="input-group-append">
                  <span class="input-group-text">px</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        class="btn btn-primary"
        (click)="clearExpiryWarningModal(0, 'embed')"
      >
        Cancel
      </button>
    </div>
  </div>
</ng-template>

<ng-template #sharebyemail let-modal class="no-border">
  <app-email
    [surveyId]="content.data['survey'].identifier"
    [surveyInfo]="content.data['survey']"
  ></app-email>
</ng-template>
<ng-template #companyModal let-modal class="no-border">
  <div class="modal-content">
    <div class="fsop-alert-modal px-3">
      <span>Edit Attributes</span>
    </div>

    <div class="fsop-alert-modal-body contact-table">
      <div class="form-group" [formGroup]="companyAttributeForm">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12 company_name">
              <div class="form-group add-required">
                <label class="control-label"
                  >Company <span class="required">*</span></label
                >
                <div class="group-input">
                  <ng-select
                    [items]="filteredCompanies$ | async"
                    bindLabel="name"
                    dropdownPosition="auto"
                    placeholder="Select company"
                    [(ngModel)]="companyName"
                    [ngModelOptions]="{ standalone: true }"
                    [loading]="searchLoading"
                    [typeahead]="typedValue$"
                    (change)="getValue($event)"
                    (clear)="itemCleared()"
                    (scrollToEnd)="onScrollToEnd('company')"
                    (keyup)="getOrganizationName($event)"
                    (keydown)="getCharacters($event)"
                    (blur)="getBlurValue()"
                  >
                  </ng-select>
                  <div class="addon">
                    <a
                      class="btn btn-outline"
                      (click)="showOptionalContainer = !showOptionalContainer"
                      ><i class="fa fa-caret-down"></i
                    ></a>
                  </div>
                </div>
                <div *ngIf="search_company_term && search_company_term.length">
                  <small
                    >Character Count: {{ search_company_term &&
                    search_company_term.length ? search_company_term.length : 0
                    }}
                  </small>
                </div>
                <span
                  *ngIf="
                    f.company.errors &&
                    (companyAttributeForm.controls.company.touched ||
                      companyAttributeForm.controls.company.dirty ||
                      isSubmitted)
                  "
                >
                  <small
                    *ngIf="f.company.errors.required"
                    class="text-danger"
                    translate
                  >
                    This field is required
                  </small>
                </span>
                <small
                  class="required"
                  *ngIf="search_company_term && search_company_term.length > 50"
                  >You can only input a maximum of 70 characters.</small
                >
                <!-- (search)="getSearchValue($event)" -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="showOptionalContainer">
          <div class="optional-fields">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Project</label>
                  <input
                    formControlName="project"
                    maxlength="80"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    class="form-control"
                  />

                  <div
                    *ngIf="
                      companyAttributeForm.controls.project.value && companyAttributeForm.controls.project.value.length
                    "
                    class="d-flex mt-1 align-items-center justify-content-between"
                  >
                    <small
                      >Character Count: {{
                      companyAttributeForm.controls.project.value &&
                      companyAttributeForm.controls.project.value.length ?
                      companyAttributeForm.controls.project.value.length : 0 }}
                    </small>
                    <div class="d-flex">
                      <input type="checkbox" formControlName="display_project" class="fsop-checkbox" id="display_project" [checked]="true">
                      <label for="display_project" class="ps-3 mb-0"> <small>Display as project</small></label>
                    </div>
                  </div>
                  <small
                    class="required"
                    *ngIf="
                      companyAttributeForm.controls.project.value &&
                      companyAttributeForm.controls.project.value.length > 50
                    "
                    >You can only input a maximum of 80 characters.</small
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Cohort</label>
                  <input
                    formControlName="cohort"
                    maxlength="80"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    class="form-control"
                  />

                  <div
                    *ngIf="
                      companyAttributeForm.controls.cohort.value && companyAttributeForm.controls.cohort.value.length
                    "
                  >
                    <small
                      >Character Count: {{
                      companyAttributeForm.controls.cohort.value &&
                      companyAttributeForm.controls.cohort.value.length ?
                      companyAttributeForm.controls.cohort.value.length : 0 }}
                    </small>
                  </div>
                  <small
                    class="required"
                    *ngIf="
                      companyAttributeForm.controls.cohort.value &&
                      companyAttributeForm.controls.cohort.value.length > 50
                    "
                    >You can only input a maximum of 80 characters.</small
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Division</label>
                  <input
                    formControlName="division"
                    maxlength="80"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    class="form-control"
                  />

                  <div
                    *ngIf="
                      companyAttributeForm.controls.division.value &&
                      companyAttributeForm.controls.division.value.length
                    "
                  >
                    <small
                      >Character Count: {{
                      companyAttributeForm.controls.division.value &&
                      companyAttributeForm.controls.division.value.length ?
                      companyAttributeForm.controls.division.value.length : 0 }}
                    </small>
                  </div>
                  <small
                    class="required"
                    *ngIf="
                      companyAttributeForm.controls.division.value &&
                      companyAttributeForm.controls.division.value.length > 50
                    "
                    >You can only input a maximum of 80 characters.</small
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Department</label>
                  <input
                    formControlName="department"
                    maxlength="80"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    class="form-control"
                  />

                  <div
                    *ngIf="
                      companyAttributeForm.controls.department.value &&
                      companyAttributeForm.controls.department.value.length
                    "
                  >
                    <small
                      >Character Count: {{
                      companyAttributeForm.controls.department.value &&
                      companyAttributeForm.controls.department.value.length ?
                      companyAttributeForm.controls.department.value.length : 0
                      }}
                    </small>
                  </div>
                  <small
                    class="required"
                    *ngIf="
                      companyAttributeForm.controls.department.value &&
                      companyAttributeForm.controls.department.value.length > 50
                    "
                    >You can only input a maximum of 80 characters.</small
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Level</label>
                  <input
                    formControlName="level"
                    maxlength="80"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    class="form-control"
                  />

                  <div
                    *ngIf="
                      companyAttributeForm.controls.level.value && companyAttributeForm.controls.level.value.length
                    "
                  >
                    <small
                      >Character Count: {{
                      companyAttributeForm.controls.level.value &&
                      companyAttributeForm.controls.level.value.length ?
                      companyAttributeForm.controls.level.value.length : 0 }}
                    </small>
                  </div>
                  <small
                    class="required"
                    *ngIf="
                      companyAttributeForm.controls.level.value && companyAttributeForm.controls.level.value.length > 50
                    "
                    >You can only input a maximum of 80 characters.</small
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Team</label>
                  <input
                    formControlName="team"
                    maxlength="80"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    class="form-control"
                  />

                  <div
                    *ngIf="companyAttributeForm.controls.team.value && companyAttributeForm.controls.team.value.length"
                  >
                    <small
                      >Character Count: {{
                      companyAttributeForm.controls.team.value &&
                      companyAttributeForm.controls.team.value.length ?
                      companyAttributeForm.controls.team.value.length : 0 }}
                    </small>
                  </div>
                  <small
                    class="required"
                    *ngIf="
                      companyAttributeForm.controls.team.value && companyAttributeForm.controls.team.value.length > 50
                    "
                    >You can only input a maximum of 80 characters.</small
                  >
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Location</label>
                  <input
                    formControlName="location"
                    maxlength="80"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    class="form-control"
                  />

                  <div
                    *ngIf="
                      companyAttributeForm.controls.location.value &&
                      companyAttributeForm.controls.location.value.length
                    "
                  >
                    <small
                      >Character Count: {{
                      companyAttributeForm.controls.location.value &&
                      companyAttributeForm.controls.location.value.length ?
                      companyAttributeForm.controls.location.value.length : 0 }}
                    </small>
                  </div>
                  <small
                    class="required"
                    *ngIf="
                      companyAttributeForm.controls.location.value &&
                      companyAttributeForm.controls.location.value.length > 50
                    "
                    >You can only input a maximum of 80 characters.</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button
        (click)="companyAttributeUpdateForCopySurvey()"
        class="btn btn-md btn-primary"
      >
        Update
      </button>
      <a
        href="javascript:void(0)"
        (click)="closeCompanyModal()"
        class="btn btn-md btn-secondary"
        >Cancel</a
      >
    </div>
  </div>
</ng-template>
<ng-template #addCompanyModal let-modal class="no-border">
  <app-alert
    [content]="alertContent"
    (onCloseModalTriggered)="onActiveModalClosed($event)"
    (cancelClicked)="closeCurrentModal($event)"
  ></app-alert>
</ng-template>


<ng-template #scheduleUpdateConfirmation let-modal>
  <div class="modal-content">
    <div class="confirm-modal-header">
      <h3 style="font-weight: 400;">Unschedule Survey</h3>
    </div>
    <div class="fsop-alert-modal-body">
      <p>
        Are you sure you want to Unschedule the survey? Please note that
        this action is permanent and cannot be undone.
      </p>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="modal.close(1)">
        Ok
      </button>
      <button class="btn btn-md btn-secondary" (click)="modal.close(0)">
        Cancel
      </button>
     
    </div>
  </div>
</ng-template>
