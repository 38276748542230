import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  AbstractControl,
} from "@angular/forms";
import {
  NgbModal,
  NgbModalRef,
  NgbDatepickerConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { AssessmentDataService } from "../../../app/core/assessment-data.service";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../environments/environment";
import { Papa } from "ngx-papaparse";
import { ISurvey } from "../../../app/models/survey.model";
import * as moment from "moment";
import { Subscription, interval, Observable, Subject, concat, of } from "rxjs";
import { EmailTimerService } from "../../core/email-timer.service";
import { HeaderDataService } from "../../../app/core/header-data.service";
import { UserDataService } from "../../../app/core/user-data";
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { FileUploaderService } from "../../../app/core/file-upload.service";
import { Project } from "../../models/project.model";
import { PortalDataService } from "../../core/portal-data.service";
declare const tinymce: any;
import * as $ from "jquery";

@Component({
  selector: "app-email",
  templateUrl: "./email.component.html",
  styleUrls: ["./email.component.scss"],
})
export class EmailComponent implements OnInit, OnDestroy {
  expirationDate: any;
  public contactsModalRef: NgbModalRef;
  public testEmailModalRef: NgbModalRef;
  @ViewChild("bulkModal", { static: false }) bulkUploaderModalRef: NgbModalRef;
  @ViewChild("guideLineModal", { static: false }) guideLinesModalRef: NgbModalRef;
  public resetSheetConfirmationAlertRef: NgbModalRef;
  public confirmationAlertRef: NgbModalRef;
  @ViewChild("alert", { static: false }) confirmationAlert: ElementRef;
  alertContent: any;
  editing = {};
  contactRoles: any[] = [];
  recepients: any = [];

  templates_loaded = false;
  templatePageNo = 0;
  initialTemplateList: any = [];
  templateName: any;
  typedValue$ = new Subject<string>();
  templates$: Observable<any>;
  filteredTemplates$: Observable<any>;
  @Input("surveyId")
  surveyId: string;


  @Input() project: Project;


  @Input()
  surveyInfo: any;
  surveyType: any;

  public selected: any[] = [];
  reminderContacts: any[] = [];
  isSubmitted: boolean = false;
  @Input()
  emailReminderData: any;
  @Input()
  templateType: any;
  emailExpiration: string;
  surveyEmailShareForm: FormGroup;
  testEmailForm: FormGroup;
  isTestSubmitted: boolean = false;
  //todo bing survey title in emailbody
  emailBody: string = ``;
  old_survey_email_ID: any;
  currentSurvey: any;

  requiredErrMsg = "This field is required";
  maxLinkDate: number = 0;

  disable_ratee = false;

  tinymce_init_obj: any;
  activeEditingField = "iframe";
  shortcodeSelected = "add_shortcode";

  cancelSubscriber: Subscription;
  isFirstEmailCancelled = false;

  timezoneNote: String = "";

  currentUserProfile: any;

  mytime: any;
  currentTimeObject: any;
  scheduleTimeVal: any;
  @ViewChild("modal", { static: false }) modal: NgbModalRef;
  modalReferenceSelect: any;
  constructor(
    public modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private assessmentDataService: AssessmentDataService,
    private papaParse: Papa,
    private emailTimerService: EmailTimerService,
    private config: NgbDatepickerConfig,
    private headerDataService: HeaderDataService,
    private userDataService: UserDataService,
    private cd: ChangeDetectorRef,
    private portalDataService: PortalDataService,
    private fileUploaderService: FileUploaderService,
    private toastService: ToastrService
  ) {
    this.createForm();
    // configure date picker
    this.configDatePicker();

    let _this = this;

    this.tinymce_init_obj = {
      newline_behavior: 'linebreak',
      min_height: 250,
      max_height: 500,
      menubar: false,
      trusted: true,
      inline: false,
      statusbar: true,
      plugins: "link image media code fullpage paste wordcount",
      default_link_target: "_blank",
      file_picker_types: "file image media",
      file_browser_callback_types: "file image media",
      automatic_uploads: true,
      image_title: false,
      fullpage_default_font_size: "11pt",
      fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
      toolbar:
        "undo redo bold italic underline link image media forecolor backcolor alignleft aligncenter alignright alignjustify bullist numlist fontsize code",
      // oninit : this.selectEditorText(),

      file_picker_callback: function (callback: any, value: any, meta: any) {
        /* Provide file and text for the link dialog */
        window["tinyMCE"].activeEditor.selection.collapse(false);

        if (meta.filetype === "file") {
          // callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("id", "tin-image");
          // input.setAttribute('accept', 'image/*');

          /*
            Note: In modern browsers input[type="file"] is functional without
            even adding it to the DOM, but that might not be the case in some older
            or quirky browsers like IE, so you might want to add it to the DOM
            just in case, and visually hide it. And do not forget do remove it
            once you do not need it anymore.
          */
          input.addEventListener(
            "change",
            function (event) {
              var files: any = input.files;
              if (files.length) {
                var file = files[0];
                var reader: any = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                  _this.assessmentDataService.loaderSubject.next({
                    bool: true,
                  });
                  /*
                  Note: Now we need to register the blob in TinyMCEs image blob
                  registry. In the next release this part hopefully won't be
                  necessary, as we are looking to handle it internally.
                */
                  var id = "blobid" + new Date().getTime();
                  var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                  var base64 = reader.result.toString().split(",")[1];
                  var blobInfo = blobCache.create(id, file, base64);
                  var formData = new FormData();
                  formData.append("filename", blobInfo.filename());
                  formData.append("upload_type", "survey_question_media");

                  formData.append("file_location", "email_editor");
                  formData.append("survey_group_type", "survey");
                  formData.append("type_id", _this.surveyId + "");

                  _this.fileUploaderService
                    .getPresignedUrl(formData)
                    .subscribe((response: any) => {
                      let pApi = response.results[0].url;
                      var obj: any = response.results[0].fields;
                      saveFileToS3(pApi, obj, callback);
                    });
                  function saveFileToS3(pApi: any, obj: any, callback: any) {
                    _this.fileUploaderService
                      .saveFileToS3(
                        pApi,
                        obj,
                        blobInfo.blob(),
                        blobInfo.filename()
                      )
                      .subscribe(
                        (data: any) => {
                          let imageUrl = `${pApi}${obj.key}`;
                          callback(imageUrl, { title: blobInfo.filename() });
                          _this.assessmentDataService.loaderSubject.next({
                            bool: false,
                          });
                        },
                        (error: any) => {
                          _this.assessmentDataService.loaderSubject.next({
                            bool: false,
                          });
                          _this.toastService.error("Something went wrong.");
                        }
                      );
                  }
                  /* call the callback and populate the Title field with the file name */
                  // callback(blobInfo.blobUri(), { title: file.name });
                };
              }
            },
            false
          );
          input.click();
        }

        /* Provide image and alt text for the image dialog */
        if (meta.filetype === "image") {
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("id", "tin-image");
          input.setAttribute("accept", "image/*");

          /*
            Note: In modern browsers input[type="file"] is functional without
            even adding it to the DOM, but that might not be the case in some older
            or quirky browsers like IE, so you might want to add it to the DOM
            just in case, and visually hide it. And do not forget do remove it
            once you do not need it anymore.
          */
          input.addEventListener(
            "change",
            function (event) {
              var files: any = input.files;
              if (files.length) {
                var file = files[0];
                var reader: any = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                  _this.assessmentDataService.loaderSubject.next({
                    bool: true,
                  });
                  /*
                  Note: Now we need to register the blob in TinyMCEs image blob
                  registry. In the next release this part hopefully won't be
                  necessary, as we are looking to handle it internally.
                */
                  var id = "blobid" + new Date().getTime();
                  var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                  var base64 = reader.result.toString().split(",")[1];
                  var blobInfo = blobCache.create(id, file, base64);

                  var formData = new FormData();
                  formData.append("filename", blobInfo.filename());
                  formData.append("upload_type", "survey_question_media");

                  formData.append("file_location", "email_editor");
                  formData.append("survey_group_type", "survey");
                  formData.append("type_id", _this.surveyId + "");

                  _this.fileUploaderService
                    .getPresignedUrl(formData)
                    .subscribe((response: any) => {
                      let pApi = response.results[0].url;
                      var obj: any = response.results[0].fields;
                      saveFileToS3(pApi, obj, callback);
                    });
                  function saveFileToS3(pApi: any, obj: any, callback: any) {
                    _this.fileUploaderService
                      .saveFileToS3(
                        pApi,
                        obj,
                        blobInfo.blob(),
                        blobInfo.filename()
                      )
                      .subscribe(
                        (data: any) => {
                          let imageUrl = `${pApi}${obj.key}`;
                          callback(imageUrl, { title: blobInfo.filename() });
                          _this.assessmentDataService.loaderSubject.next({
                            bool: false,
                          });
                        },
                        (error: any) => {
                          _this.assessmentDataService.loaderSubject.next({
                            bool: false,
                          });
                          _this.toastService.error("Something went wrong.");
                        }
                      );
                  }

                  /* call the callback and populate the Title field with the file name */
                  // callback(blobInfo.blobUri(), { title: file.name });
                };
              }
            },
            false
          );
          input.click();
        }

        /* Provide alternative source and posted for the media dialog */
        if (meta.filetype === "media") {
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("id", "tin-image");
          input.setAttribute("accept", "video/*");

          /*
            Note: In modern browsers input[type="file"] is functional without
            even adding it to the DOM, but that might not be the case in some older
            or quirky browsers like IE, so you might want to add it to the DOM
            just in case, and visually hide it. And do not forget do remove it
            once you do not need it anymore.
          */
          input.addEventListener(
            "change",
            function (event) {
              var files: any = input.files;
              if (files.length) {
                var file = files[0];
                //200mb
                if (file.size > 200000000) {
                  _this.toastrService.error(
                    "Please enter file size less than 200MB."
                  );
                  return;
                }
                var reader: any = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                  _this.assessmentDataService.loaderSubject.next({
                    bool: true,
                  });
                  /*
                  Note: Now we need to register the blob in TinyMCEs image blob
                  registry. In the next release this part hopefully won't be
                  necessary, as we are looking to handle it internally.
                */
                  var id = "blobid" + new Date().getTime();
                  var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                  var base64 = reader.result.toString().split(",")[1];
                  var blobInfo = blobCache.create(id, file, base64);

                  var formData = new FormData();
                  formData.append("filename", blobInfo.filename());
                  formData.append("upload_type", "survey_question_media");

                  formData.append("file_location", "email_editor");
                  formData.append("survey_group_type", "survey");
                  formData.append("type_id", _this.surveyId + "");

                  _this.fileUploaderService
                    .getPresignedUrl(formData)
                    .subscribe((response: any) => {
                      let pApi = response.results[0].url;
                      var obj: any = response.results[0].fields;
                      saveFileToS3(pApi, obj, callback);
                    });
                  function saveFileToS3(pApi: any, obj: any, callback: any) {
                    _this.fileUploaderService
                      .saveFileToS3(
                        pApi,
                        obj,
                        blobInfo.blob(),
                        blobInfo.filename()
                      )
                      .subscribe(
                        (data: any) => {
                          let imageUrl = `${pApi}${obj.key}`;
                          callback(imageUrl, { title: blobInfo.filename() });
                          _this.assessmentDataService.loaderSubject.next({
                            bool: false,
                          });
                        },
                        (error: any) => {
                          _this.assessmentDataService.loaderSubject.next({
                            bool: false,
                          });
                          _this.toastService.error("Something went wrong.");
                        }
                      );
                  }
                  /* call the callback and populate the Title field with the file name */
                  // callback(blobInfo.blobUri(), { title: file.name });
                };
              }
            },
            false
          );
          input.click();
          // callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
        }
      },

      video_template_callback: function (data: any) {
        return (
          '<video width="' +
          data.width +
          '" height="' +
          data.height +
          '"' +
          (data.poster ? ' poster="' + data.poster + '"' : "") +
          ' controls="controls">\n' +
          '<source src="' +
          data.source +
          '"' +
          (data.source1mime ? ' type="' + data.source1mime + '"' : "") +
          " />\n" +
          (data.source2
            ? '<source src="' +
            data.source2 +
            '"' +
            (data.source2mime ? ' type="' + data.source2mime + '"' : "") +
            " />\n"
            : "") +
          "</video>"
        );
      },

      setup: function (ed: any) {
        // var allowedKeys = [8, 37, 38, 39, 40, 46]; // backspace, delete and cursor keys
        // ed.on('keydown', function (e: any) {
        //   if (allowedKeys.indexOf(e.keyCode) != -1) return true;

        //   if ((_this.checkedQuestions[0] && (_this.checkedQuestions[0]['_type'] == 1 || _this.checkedQuestions[0]['_type'] == 2 || _this.checkedQuestions[0]['_type'] == 5)) && (_this.tinymce_getContentLength() + 1 > this.settings.max_chars)) {
        //     e.preventDefault();
        //     e.stopPropagation();
        //     return false;
        //   }
        //   return true;
        // });
        ed.on("keyup", function (e: any) {
          // _this.tinymce_updateCharCounter(
          //   this,
          //   _this.tinymce_getContentLength()
          // );
          // document.querySelector('#word_limit')['innerHTML'] = `
          //   <div> <div class="${((_this.checkedQuestions[0] && (_this.checkedQuestions[0]['_type'] == 1 || _this.checkedQuestions[0]['_type'] == 2 || _this.checkedQuestions[0]['_type'] == 5)) && _this.tinymce_getContentLength() > 450)? 'red-text' : ''}">` + _this.tinymce_getContentLength() + `${_this.tinymce_getContentLength() > 1? ' WORDS' : ' WORD' } </div>` +
          //   `${ (_this.checkedQuestions[0] && (_this.checkedQuestions[0]['_type'] == 1 || _this.checkedQuestions[0]['_type'] == 2 || _this.checkedQuestions[0]['_type'] == 5))? _this.getWarning() : ''}` +
          //   '</div>';
        });
      },
      paste_preprocess: function (plugin: any, args: any) {
        var editor = tinymce.get(tinymce.activeEditor.id);
        var len = editor.contentDocument.body.innerText.trim().length
          ? editor.contentDocument.body.innerText.length
          : 0;
        // if ((_this.checkedQuestions[0] && (_this.checkedQuestions[0]['_type'] == 1 || _this.checkedQuestions[0]['_type'] == 2 || _this.checkedQuestions[0]['_type'] == 5)) && (len + _this.extractContent(args.content).length > editor.settings.max_chars)) {
        //   alert('Pasting this exceeds the maximum allowed number of ' + editor.settings.max_chars + ' characters for the input.');
        //   args.content = '';
        // }
        // _this.tinymce_updateCharCounter(
        //   editor,
        //   len + _this.extractContent(args.content).length
        // );
      },
    };
  }

  updateMyTime() {
    if (!this.mytime && this.currentTimeObject) {
      this.mytime = this.currentTimeObject;
    }
  }

  scheduleTimeChanged(event: any) {
    if (event) {
      this.currentTimeObject = event;
      this.scheduleTimeVal = this.formatTime(event);
    }
  }

  formatTime(time: any) {
    let hh = time.hour;
    let m = time.minute;
    let dd = "AM";
    let h = hh;
    if (h >= 12) {
      h = hh - 12;
      dd = "PM";
    }
    if (h == 0) {
      h = 12;
    }
    m = m < 10 ? "0" + m : m;

    // if you want 2 digit hours:
    h = h < 10 ? "0" + h : h;

    var pattern = new RegExp("0?" + hh + ":" + m);

    var replacement = h + ":" + m;
    /* if you want to add seconds
    replacement += ":"+s;  */
    replacement += " " + dd;

    return replacement;
  }
  getNameForSubject() {
    // if (!this.currentSurvey.ratee) {
    //   this.surveyEmailShareForm.controls['subject'].setValue(`360 Feedback Request for ${this.surveyEmailShareForm.value.ratee_first_name? this.surveyEmailShareForm.value.ratee_first_name : ''} ${this.surveyEmailShareForm.value.ratee_last_name? this.surveyEmailShareForm.value.ratee_last_name : ''}`);
    // }
  }

  // minDate: any = {
  //   year: 1970,
  //   month: 1,
  //   day: 1,
  // };

  getMinDate() {
    let tzData = this.route.snapshot.data["Timezone"]
      ? this.route.snapshot.data["Timezone"]
      : null;
    if (tzData) this.useTimezone(tzData.user_timezone_current_time);

    if (!tzData) {
      let id = this.userDataService.userInfo
        ? this.userDataService.userInfo.id
        : this.headerDataService.userId
          ? this.headerDataService.userId
          : null;
      if (this.route.snapshot.data["Timezone"]) {
        let data = this.route.snapshot.data["Timezone"];
        this.useTimezone(data.user_timezone_current_time);
      } else if (id) {
        this.userDataService.getCaTimezoneData(id).subscribe((data) => {
          this.useTimezone(data.user_timezone_current_time);
        });
      } else {
        this.userDataService.me().subscribe((data) => {
          this.useTimezone(data.user_timezone_current_time);
        });
      }
    }
  }

  expDateDefault: any;
  defaultDate: any;
  useTimezone(userTimezoneTime: any) {
    if (this.surveyInfo['status'] != "scheduled") {
      this.configureMinDate(userTimezoneTime);
      let date = moment.parseZone(userTimezoneTime); //todays date
      let expDate = moment(date).add(21, "days");
      this.expDateDefault = {
        year: +expDate.format("YYYY"),
        month: +expDate.format("M"),
        day: +expDate.format("D"),
      };

      let defDate = {
        year: +date.format("YYYY"),
        month: +date.format("M"),
        day: +date.format("D"),
      };

      this.defaultDate = moment(
        [
          defDate.year.toString(),
          defDate.month.toString(),
          defDate.day.toString(),
        ],
        "YYYY-MM-DD"
      );
    }
  }

  configureMinDate(userTimezoneTime: any) {
    let date = moment.parseZone(userTimezoneTime); //todays date
    let tomDate = moment(date).add(1, "days");
    this.config.minDate = {
      year: tomDate.get("year"),
      month: tomDate.get("month") + 1,
      day: tomDate.get("date"),
    };
  }
  configDatePicker(): void {
    const currDate = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);

    const actual_tomorrow = moment(
      [
        currDate.getFullYear().toString(),
        currDate.getMonth() + 1,
        currDate.getUTCDate() + 1,
      ],
      "YYYY-MM-DD"
    );
    // this.config.minDate = {
    //   year: actual_tomorrow.get("year"),
    //   month: actual_tomorrow.get("month") + 1,
    //   day: actual_tomorrow.get("date"),
    // };
    this.config.maxDate = {
      year: actual_tomorrow.get("year") + 20,
      month: 12,
      day: 31,
    };

    this.config.outsideDays = "hidden";

    if (
      tomorrow.getFullYear() != actual_tomorrow.get("year") ||
      tomorrow.getMonth() != actual_tomorrow.get("month") ||
      tomorrow.getDate() != actual_tomorrow.get("date")
    ) {
      this.getTimezone();
    } else {
      this.timezoneNote = "Please note that dates are in UTC format.";
    }
  }

  setDatepickerConfigAccToTimezone() { }

  getDefaultDateForScheduledSurvey() {
    if (this.currentSurvey && this.currentSurvey.status == "scheduled") {
      let date = this.currentSurvey.launch_at.split("T")[0].split("-");
      let time = this.currentSurvey.launch_at.split("T")[1].split(":");
      let c1 =
        date[0] +
        "-" +
        date[1] +
        "-" +
        date[2] +
        " " +
        time[0] +
        ":" +
        time[1] +
        ":00";

      let defDate = {
        year: +date[0],
        month: +date[1],
        day: +date[2],
      };

      this.defaultDate = moment(
        [
          defDate.year.toString(),
          defDate.month.toString(),
          defDate.day.toString(),
        ],
        "YYYY-MM-DD"
      );

      let d = moment(
        [
          defDate.year.toString(),
          defDate.month.toString(),
          defDate.day.toString(),
        ],
        "YYYY-MM-DD"
      );
      let tomDate = moment(d).add(1, "days");
      this.config.minDate = {
        year: tomDate.get("year"),
        month: tomDate.get("month") + 1,
        day: tomDate.get("date"),
      };
    }
  }
  fillExpiryDates() {
    this.getDefaultDateForScheduledSurvey();
    let _expiry = this.currentSurvey.expiry_date;
    if (_expiry && !_expiry.includes("2100")) {
      // if (this.currentSurvey.expiry_date) {
      this.scheduleTimeVal = moment(this.currentSurvey.expiry_date)
        .parseZone()
        .format("hh:mm A");
      this.mytime = {
        hour: +moment(this.currentSurvey.expiry_date).parseZone().format("HH"),
        minute: +moment(this.currentSurvey.expiry_date)
          .parseZone()
          .format("mm"),
        second: 0,
      };
      const date = moment(this.currentSurvey.expiry_date)
        .parseZone()
        .format("YYYY-MM-DD");

      // var today = moment([currDate.getFullYear().toString(), currDate.getMonth() + 1, currDate.getUTCDate()]);
      this.expirationDate = {
        year: parseInt(date.split("-")[0]),
        month: parseInt(date.split("-")[1]),
        day: parseInt(date.split("-")[2]),
      };
      // let expiryDate = moment([date.split("-")[0], date.split("-")[1], date.split("-")[2]]);
      // this.surveyEmailShareForm.controls["expire_days"].setValue(expiryDate.diff(today, "days"));
      setTimeout(() => {
        this.changeFormat("date_picker_format", this.expirationDate);
      });

      let survey_expiryDate = moment(
        [
          this.expirationDate.year.toString(),
          this.expirationDate.month.toString(),
          this.expirationDate.day.toString(),
        ],
        "YYYY-MM-DD"
      );

      this.maxLinkDate = survey_expiryDate.diff(this.defaultDate, "days");

      if (
        !this.emailReminderData ||
        (this.emailReminderData && !this.emailReminderData.expirationDate)
      ) {

        this.surveyEmailShareForm.controls["expire_days"].setValue(
          parseInt(survey_expiryDate.diff(this.defaultDate, "days").toString())
        );
      } else {
        if (this.emailReminderData.expirationDate) {
          let reminder_Date = moment(this.emailReminderData.expirationDate)
            .parseZone()
            .format("YYYY-MM-DD");
          let expiryDate = moment(
            [
              reminder_Date.split("-")[0].toString(),
              reminder_Date.split("-")[1].toString(),
              reminder_Date.split("-")[2].toString(),
            ],
            "YYYY-MM-DD"
          );


          this.surveyEmailShareForm.controls["expire_days"].setValue(
            parseInt(expiryDate.diff(this.defaultDate, "days").toString())
          );
        }
      }
    } else {
      //if scheduled type survey calculate expiry date wrt launch date
      if (this.surveyInfo.status == "scheduled") {
        let date = this.currentSurvey.launch_at.split("T")[0].split("-");
        let time = this.currentSurvey.launch_at.split("T")[1].split(":");
        let c1 =
          date[0] +
          "-" +
          date[1] +
          "-" +
          date[2] +
          " " +
          time[0] +
          ":" +
          time[1] +
          ":00";

        let defDate = {
          year: +date[0],
          month: +date[1],
          day: +date[2],
        };
        let dateformed = moment(c1).parseZone();
        let expDate = moment(dateformed).add(21, "days");

        this.expDateDefault = {
          year: +expDate.format("YYYY"),
          month: +expDate.format("M"),
          day: +expDate.format("D"),
        };

        this.defaultDate = moment(
          [
            defDate.year.toString(),
            defDate.month.toString(),
            defDate.day.toString(),
          ],
          "YYYY-MM-DD"
        );
      }
      this.expirationDate = this.expDateDefault;

      this.scheduleTimeVal = "11:59 PM";
      this.mytime = {
        hour: 23,
        minute: 59,
        second: 0,
      };
      setTimeout(() => {
        this.changeFormat("date_picker_format", this.expirationDate);
      });

      let survey_expiryDate = moment(
        [
          this.expirationDate.year.toString(),
          this.expirationDate.month.toString(),
          this.expirationDate.day.toString(),
        ],
        "YYYY-MM-DD"
      );
      this.maxLinkDate = survey_expiryDate.diff(this.defaultDate, "days");
      this.surveyEmailShareForm.controls["expire_days"].setValue(
        parseInt(survey_expiryDate.diff(this.defaultDate, "days").toString())
      );
    }
  }

  getColumnWidth(name: any) {
    if (!name || name.length < 10) {
      return 150;
    } else if (name.length < 20) {
      return 200;
    } else if (name.length < 30) {
      return 300;
    }
    return 320;
  }

  ngOnDestroy() {
    this.contacts_modal_visible = false;
    this.cancelSubscriber && this.cancelSubscriber.unsubscribe();
    this.profileDataSubscription && this.profileDataSubscription.unsubscribe();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

  }

  profileDataSubscription: Subscription;

  ngOnInit() {
    this.getContactRoles(this.surveyId);
    this.listenToColumnAddition();
    this.getMinDate();
    this.profileDataSubscription = this.headerDataService.userProfile.subscribe(
      (userProfile) => {
        if (userProfile != undefined) {
          this.currentUserProfile = userProfile;
          if (this.currentUserProfile.user_domain) {
            this.surveyEmailShareForm.controls.from_email.setValue(
              "surveyinvite@" + this.currentUserProfile.user_domain
            );
          }
          if (this.currentUserProfile["user_timezone_display"]) {
            this.timezoneNote = `Please note that the current timezone is set to  ${this.currentUserProfile["user_timezone_display"].split(":")[0]
              }.`;
          }
        }
      }
    );
    this.currentUserProfile = this.headerDataService.getProfileDetail();
    if (this.currentUserProfile.user_domain) {
      this.surveyEmailShareForm.controls.from_email.setValue(
        "surveyinvite@" + this.currentUserProfile.user_domain
      );
    }
    if (this.currentUserProfile["user_timezone_display"]) {
      this.timezoneNote = `Please note that the current timezone is set to ${this.currentUserProfile["user_timezone_display"].split(":")[0]
        }.`;
    }
    this.emailTimerService.setCancelTimerState(false);
    this.assessmentDataService
      .getSurvey(this.surveyId)
      .toPromise()
      .then((survey: any) => {
        this.currentSurvey = survey;
        this.surveyEmailShareForm.controls["reply_to_email"].patchValue(
          survey.creator_email
        );
        this.surveyEmailShareForm.controls["from_name"].patchValue(
          survey.creator
        );
        this.surveyType = survey.survey_type;
        if (this.surveyType === 1) {
          if (localStorage.getItem("ratee")) {
            let _ratee = JSON.parse(localStorage.getItem("ratee") as string);
            if (
              !survey["ratee"] &&
              _ratee.surveyId == this.route.snapshot.params["id"]
            ) {
              survey["ratee"] = _ratee.ratee;
            } else {
              localStorage.removeItem("ratee");
            }
          }

          if (!this.project) {
            let ratee_first_name = new FormControl(
              survey["ratee"] && survey["ratee"]["first_name"],
              [Validators.required]
            );
            let ratee_last_name = new FormControl(
              survey["ratee"] && survey["ratee"]["last_name"],
              [Validators.required]
            );
            let ratee_email_id = new FormControl(
              survey["ratee"] && survey["ratee"]["email"],
              [Validators.required, Validators.email]
            );

            this.surveyEmailShareForm.addControl(
              "ratee_first_name",
              ratee_first_name
            );
            this.surveyEmailShareForm.addControl(
              "ratee_last_name",
              ratee_last_name
            );
            this.surveyEmailShareForm.addControl(
              "ratee_email_id",
              ratee_email_id
            );
          }


          if (this.surveyType === 1 && !this.project) {
            if (
              !localStorage.getItem("ratee") &&
              !survey["ratee"] &&
              !this.isScheduleEdit()
            ) {
              this.surveyEmailShareForm.valueChanges.subscribe((data: any) => {
                this.recepients[0] = {
                  email: this.surveyEmailShareForm.controls.ratee_email_id.value
                    ? this.surveyEmailShareForm.controls.ratee_email_id.value
                    : "",
                  first_name: this.surveyEmailShareForm.controls
                    .ratee_first_name.value
                    ? this.surveyEmailShareForm.controls.ratee_first_name.value
                    : "",
                  last_name: this.surveyEmailShareForm.controls.ratee_last_name
                    .value
                    ? this.surveyEmailShareForm.controls.ratee_last_name.value
                    : "",
                  role: 2,
                };
              });
            }
          }

          if (
            survey["ratee"] &&
            survey["ratee"]["first_name"] &&
            survey["ratee"]["last_name"]
          ) {
            this.disable_ratee = true;
            this.surveyEmailShareForm.controls["subject"].setValue(
              `Feedback Request from {ParticipantName}`
            );
            // this.surveyEmailShareForm.controls['subject'].setValue(`Reminder: ${survey['ratee']['first_name']} ${survey['ratee']['last_name']} Values Your Insight`);
          } else {
            this.disable_ratee = false;
            this.surveyEmailShareForm.controls["subject"].setValue(
              `Feedback Request from {ParticipantName}`
            );
          }
        } else {
          this.surveyEmailShareForm.controls["subject"].setValue(
            "Survey Participation Request"
          );
        }

        this.initContacts();
      })
      .then(() => {
        if (this.emailReminderData != undefined) {
          if (
            this.emailReminderData &&
            !this.emailReminderData.expirationDate
          ) {
            const date = moment(this.currentSurvey.expiry_date)
              .parseZone()
              .format("YYYY-MM-DD");
            this.config.minDate = {
              year: parseInt(date.split("-")[0]),
              month: parseInt(date.split("-")[1]),
              day: parseInt(date.split("-")[2]),
            };
            // this.surveyEmailShareForm.controls['expire_days'].clearValidators();
            // this.surveyEmailShareForm.controls['expire_days'].updateValueAndValidity();
            // this.surveyEmailShareForm.controls['expire_days'].setValue(null);
          }
          this.fillExpiryDates();

          if (this.currentSurvey.survey_type != 1) {
            //original email body
            // this.emailBody = `
            // <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%;" data-mce-style="margin-bottom: 0cm; line-height: 100%;">
            //   <style type="text/css">
            //     @page { margin: 2cm }
            //     p { margin-bottom: 0.25cm; direction: ltr; line-height: 120%; text-align: left; orphans: 2; widows: 2 }
            //     p.western { so-language: en-GB }
            //     a:link { color: #0000ff }
            //   </style>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">Dear {FirstName},
            //   </font></font></p>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">Your participation was recently requested in the survey:  ${this.currentSurvey.title}.
            //   As your input is valuable, please complete the survey no later than {DayPriorExpiration}.
            //   </font></font></p>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">To complete this survey, please <a href="{SurveyLink}">click here</a>
            //   or copy and paste the below link into your browser:</font></font></p>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <a href="{SurveyLink}" lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">{SurveyLink}</font></font></a>
            //   </p>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">Thank you for participating.
            //   </font></font></p>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="1" style="font-size: 7pt">Want
            //   to unsubscribe? <a href="{UnsubscribeLink}">Unsubscribe Link</a>.</font></font></p>
            //   <br data-mce-bogus="1">
            // </p>`;
          } else {
            this.surveyEmailShareForm.controls["subject"].setValue(
              `{ParticipantName} Values Your Perspective`
            );
            //original email body
            // this.emailBody = `
            // <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%;" data-mce-style="margin-bottom: 0cm; line-height: 100%;">
            //   <style type="text/css">
            //     @page { margin: 2cm }
            //     p { margin-bottom: 0.25cm; direction: ltr; line-height: 120%; text-align: left; orphans: 2; widows: 2 }
            //     p.western { so-language: en-GB }
            //     a:link { color: #0000ff }
            //   </style>

            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">Dear
            //   {FirstName},</font></font></p>

            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">
            //     Recently, you were asked to provide feedback to assist {ParticipantName} in becoming the most effective
            //     leader possible. According to our records, your response has not yet been received and the deadline for
            //     response is quickly approaching. If you have started your survey, you may need to re-click the link to
            //     submit it. However, if you have not yet started the survey, we request that you do so quickly to ensure
            //     your perspective is captured.
            //   </font></font></p>

            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">
            //     Your input is valuable. Please complete the speciality survey no later than {DayPriorExpiration}.
            //   </font></font></p>

            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">
            //     You can access the survey by <a href="{SurveyLink}">clicking here</a> or copying and pasting the below link into your browser:
            //   </font></font></p>

            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">
            //     {SurveyLink}
            //   </font></font></p>

            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">
            //     If you have any questions about this initiative, please do not hesitate to reach out to us.
            //   </font></font></p>

            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">
            //     Thank you for participating.
            //   </font></font></p>

            //   <br>

            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="1" style="font-size: 7pt">Want
            //   to unsubscribe? <a href="{UnsubscribeLink}">Unsubscribe Link</a>.</font></font></p><br data-mce-bogus="1">

            //   <br>
            // </p>`;
          }
          this.old_survey_email_ID = this.emailReminderData.old_survey_email_ID;
          this.reloadContacts();
        } else if (
          this.currentSurvey &&
          this.currentSurvey.status == "scheduled" &&
          this.currentSurvey.scheduled_email &&
          this.currentSurvey.scheduled_email.launch_at
        ) {
          this.patchControlsForSavedEmail();
        } else {
          let expire_days = new FormControl("", [Validators.required]);
          this.surveyEmailShareForm.addControl("expire_days", expire_days);

          this.fillExpiryDates();

          if (this.currentSurvey.survey_type != 1) {
            //original email body
            // this.emailBody = `
            // <p>
            //   <style type="text/css">
            //     @page { margin: 2cm }
            //     p { margin-bottom: 0.25cm; direction: ltr; line-height: 120%; text-align: left; orphans: 2; widows: 2 }
            //     p.western { so-language: en-GB }
            //     a:link { color: #0000ff }
            //   </style>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">Dear {FirstName},</font></font></p>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">We wanted to request your participation on our survey: ${this.currentSurvey.title}</font></font></p>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">To complete this survey, please <a href="{SurveyLink}">click here</a> or copy and paste the below link into your browser:
            //   </font></font></p>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt"><a href="{SurveyLink}" lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">{SurveyLink}</font></font></a></font></font></p>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">Thank you for participating.</font></font></p>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="1" style="font-size: 7pt">Want
            //   to unsubscribe? <a href="{UnsubscribeLink}">Unsubscribe Link</a>.</font></font></p><br data-mce-bogus="1">
            //   <p>&nbsp;</p>
            // </p>`;
          } else {
            //original email body
            // this.emailBody = `
            // <p>
            //   <style type="text/css">
            //     @page { margin: 2cm }
            //     p { margin-bottom: 0.25cm; direction: ltr; line-height: 120%; text-align: left; orphans: 2; widows: 2 }
            //     p.western { so-language: en-GB }
            //     a:link { color: #0000ff }
            //   </style>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">Dear
            //   {FirstName},</font></font></p>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">{ParticipantName}</font></font><font color="#000000"><font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt"><span style="background: #ffffff">
            //   is requesting your participation in a speciality feedback survey. This
            //   survey, powered by Ambivista CompleteView™, </span></font></font></font><font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">will
            //   provide insight into their personal effectiveness. Through this
            //   initiative, you will have an opportunity to provide candid input into
            //   their strengths and opportunities for development.</font></font><font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt"></font></font><font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt"></font></font></p>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">
            //   We ask that you please provide your feedback as soon as possible, but no later than {DayPriorExpiration}.
            //   </font></font><font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt"></font></font></p>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">
            //   We recommend completing your survey in one sitting. However, if you must step away from the survey prior to completion, please click the save button and use the link in this email to return to the survey.
            //   </font></font><font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt"></font></font></p>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">To
            //   complete this survey, please <a href="{SurveyLink}">click here</a> or
            //   copy and paste the below link into your browser:</font></font></p>
            //   <a href="{SurveyLink}" lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">{SurveyLink}</font></font></a><p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%"><font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt"><br data-mce-bogus="1"></font></font></p>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="2" style="font-size: 11pt">Thank
            //   you for participating.</font></font></p>
            //   <p lang="en-GB" class="western" style="margin-bottom: 0cm; line-height: 100%">
            //   <font face="arial, helvetica, sans-serif"><font size="1" style="font-size: 7pt">Want
            //   to unsubscribe? <a href="{UnsubscribeLink}">Unsubscribe Link</a>.</font></font></p><br data-mce-bogus="1">
            // </p>`;
          }
        }

        setTimeout(() => {
          let _this = this;
          if (tinymce)
            tinymce &&
              tinymce.activeEditor.on("focus", function (e: any) {
                _this.activeEditingField = "iframe";
              });
        });
      })
      .catch((err) => { });

    this.cancelSubscriber = this.emailTimerService.cancelTimerState.subscribe(
      (data: any) => {
        if (
          data &&
          data.content_data &&
          data.content_data.surveyId == this.currentSurvey["identifier"]
        ) {
          localStorage.removeItem("ratee");
          delete this.currentSurvey.ratee;
          this.isFirstEmailCancelled = true;
        }
      }
    );
  }

  isScheduleEdit() {
    if (
      this.currentSurvey &&
      this.currentSurvey.status == "scheduled" &&
      this.currentSurvey.scheduled_email &&
      this.currentSurvey.launch_at
    ) {
      return true;
    }
    return false;
  }
  patchControlsForSavedEmail() {
    let expire_days = new FormControl("", [Validators.required]);
    this.surveyEmailShareForm.addControl("expire_days", expire_days);

    this.fillExpiryDates();

    let scheduled_email = this.currentSurvey.scheduled_email;
    this.surveyEmailShareForm.controls.from_email.patchValue(
      scheduled_email.from_email
    );
    this.surveyEmailShareForm.controls["reply_to_email"].patchValue(
      scheduled_email.reply_to_email
    );
    this.surveyEmailShareForm.controls["from_name"].patchValue(
      scheduled_email.from_name
    );

    this.surveyEmailShareForm.controls["subject"].setValue(
      scheduled_email.subject
    );
    this.emailBody = scheduled_email.message;
    this.recepients = [];
    if (scheduled_email.recipients.length >= 10) {
      this.recepients = scheduled_email.recipients;
    } else {
      for (let i = 0; i < 10; i++) {
        let recepient = scheduled_email.recipients[i];
        if (recepient) {
          this.recepients.push({
            email: recepient.email,
            first_name: recepient.first_name,
            last_name: recepient.last_name,
            role: recepient.role ? recepient.role : null,
          });
        } else {
          this.recepients.push({
            email: "",
            first_name: "",
            last_name: "",
          });
        }
      }
    }
    if (this.surveyType === 1) {
      let firstRec = this.currentSurvey.scheduled_email.recipients[0];

      this.surveyEmailShareForm.controls["ratee_email_id"].patchValue(
        firstRec.email
      );
      this.surveyEmailShareForm.controls["ratee_first_name"].patchValue(
        firstRec.first_name
      );
      this.surveyEmailShareForm.controls["ratee_last_name"].patchValue(
        firstRec.last_name
      );
    }
  }

  initContacts(): void {
    switch (this.surveyType) {
      case 0:
        for (let i = 0; i < 10; i++) {
          this.recepients.push({
            email: "",
            first_name: "",
            last_name: "",
          });
        }
        break;

      case 1:
        for (let i = 0; i < 10; i++) {
          this.recepients.push({
            email: "",
            first_name: "",
            last_name: "",
            role: null,
          });
        }
        if (!this.project) {
          this.fillSelfToContactModal();
        }
        break;

      default:
        break;
    }
  }

  fillSelfToContactModal() {
    if (this.surveyType == 1 && !this.currentSurvey.ratee) {
      let obj = {
        email: this.surveyEmailShareForm.controls.ratee_email_id.value
          ? this.surveyEmailShareForm.controls.ratee_email_id.value
          : "",
        first_name: this.surveyEmailShareForm.controls.ratee_first_name.value
          ? this.surveyEmailShareForm.controls.ratee_first_name.value
          : "",
        last_name: this.surveyEmailShareForm.controls.ratee_last_name.value
          ? this.surveyEmailShareForm.controls.ratee_last_name.value
          : "",
        role: 2,
      };

      this.recepients[0].email = obj.email;
      this.recepients[0].first_name = obj.first_name;
      this.recepients[0].first_name = obj.first_name;
      this.recepients[0].role = obj.role;
    }
  }

  getTimezone() {
    let _d = new Date();
    let _e = _d.toString().split(" ");
    this.timezoneNote =
      "Please note that dates are in UTC format. Your current timezone is";
    for (let index = 5; index < _e.length; index++) {
      this.timezoneNote += " " + _e[index];
    }
  }

  getContactRoles(id: any): void {
    this.assessmentDataService.getContactsRoles2(id).subscribe(
      (response: any) => {
        if (!this.emailReminderData) {
          // response.splice(0, 1);
          let obj = { id: -1, name: "+ Add New" };
          response.splice(0, 1, obj);
        }
        this.contactRoles = response;
      },
      (err) => { }
    );
  }

  public reloadContacts(): void {
    this.reminderContacts = [];
    this.recepients = [];
    // this.selected = [];
    // let _selected = JSON.parse(JSON.stringify(this.selected))
    this.selected = [];
    let rows: any = [];

    const old_survey_email_ID_type = typeof this.old_survey_email_ID;

    if (old_survey_email_ID_type === "string") {
      this.assessmentDataService
        .getReminderContacts(this.old_survey_email_ID)
        .subscribe((contacts: any) => {
          contacts.forEach((c: any) => {
            let obj = c;
            if (this.surveyType == 1) {
              obj["role"] = this.contactRoles.filter((role) => {
                // Normalize the names by trimming any trailing 's' for comparison
                return role.name.replace(/s+$/, '') == c.role.replace(/s+$/, '');
              })[0];
            }
            rows.push(obj);
          });
        
          this.reminderContacts = [...rows];
          this.editCaseForColumn(this.reminderContacts);
          let _selected = JSON.parse(JSON.stringify(this.reminderContacts));
          _selected.forEach((val: any) => {
            let obj = val;
            if (this.surveyType == 1) {
              obj["role"] = val.role.id;
            }
            this.recepients.push(obj);
          });
        });
    } else {
      // (this.old_survey_email_ID as []).forEach((id: string) => {
      let apiToCall;
      if (this.project) {
        apiToCall = this.portalDataService.getReminderContacts(this.project.identifier)
      } else {
        apiToCall = this.assessmentDataService.getAllReminderContacts(this.surveyId)
      }
      apiToCall
        .subscribe((contacts: any[]) => {
          contacts.forEach((c: any) => {
            let obj = c;

            if (this.surveyType === 1) {
              obj["role"] = this.contactRoles.filter((role) => {
                return (role.name == c.role) || (role.id == c.role);
              })[0];
            }

            let fetchedContacts = this.reminderContacts.find((rc: any) => {
              return (
                rc.email === c.email &&
                rc.first_name === c.first_name &&
                rc.last_name === c.last_name
              );
            });
            if (fetchedContacts === undefined) {
              rows.push(obj);
            }
          });

          this.reminderContacts = [...rows];
          this.editCaseForColumn(this.reminderContacts);
          let _selected = JSON.parse(JSON.stringify(this.reminderContacts));
          _selected.forEach((val: any) => {
            let obj = val;
            if (this.surveyType == 1) {
              obj["role"] = val.role.id;
            }
            this.recepients.push(obj);
          });
        });
      // });
    }
  }

  editCaseForColumn(arr: any) {
    if (!arr.length) {
      return;
    }
    let obj = arr[0];
    let col: any = [];
    for (let key of Object.keys(obj)) {
      if (
        key == "email" ||
        key == "first_name" ||
        key == "last_name" ||
        key == "id" ||
        key == "role" ||
        key == "uuid" ||
        key == "status"
      ) {
        //do nothing
      } else {
        let obj = {};
        obj["name"] = key;
        col.push(obj);
      }
    }

    this.columns = col.reverse();
  }

  modelChanged(event: any, keyName: any) {
    this.cd.detectChanges();
    if (this.recepients && this.recepients.length)
      this.recepients[0][keyName] = event;
  }


  onShortCodeSelect({ shortcode, command, code ,content }) {


    if (shortcode == "ratee_name" && this.activeEditingField == "subject") {
      this.surveyEmailShareForm.controls["subject"].setValue(
        `${this.surveyEmailShareForm.controls["subject"].value} ${code}`
      );
      let _x = $("[formcontrolName=subject]");
      _x.focus();
    }
    else {

      tinymce.activeEditor.execCommand(
        command,
        false,
        content
      );

    }
  }

  public onSelect({ selected }: any): void {
    this.recepients.length = 0;
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.selected.forEach((val: any) => {
      let obj = {
        email: val.email,
        first_name: val.first_name,
        last_name: val.last_name,
      };
      if (this.surveyType == 1) {
        obj["role"] = val.role.id;
      }
      this.recepients.push(obj);
    });
  }

  public save(): void {
    this.isSubmitted = true;
    let recipientsArray = this.recepients.filter(
      (recepient: any) =>
        (recepient.email !== "" && recepient.first_name !== "") ||
        recepient.last_name !== ""
    );

    let emailData: any = {
      recipients: recipientsArray,
      ...this.surveyEmailShareForm.value,
    };

    emailData["subject"] = this.surveyEmailShareForm.value.subject.replace(
      /{ParticipantName}/g,
      `${this.surveyEmailShareForm.value.ratee_first_name} ${this.surveyEmailShareForm.value.ratee_last_name}`
    );

    if (this.emailReminderData === undefined) {
      let time =
        this.mytime.hour.toString() +
        ":" +
        this.mytime.minute.toString() +
        ":00";
      emailData["survey_expiry_date"] =
        this.expirationDate.year.toString() +
        "-" +
        this.expirationDate.month.toString() +
        "-" +
        this.expirationDate.day.toString();
      if (time) {
        emailData["survey_expiry_date"] =
          emailData["survey_expiry_date"] + "T" + time;
      }
    }

    if (this.surveyEmailShareForm.valid) {
      if (localStorage.getItem("ratee")) {
        let _ratee = JSON.parse(localStorage.getItem("ratee") as string);
        if (_ratee.surveyId == this.route.snapshot.params["id"]) {
          this.toastrService.warning(
            "Participant emails must be distributed before any other rater emails."
          );
          return;
        }
      }

      if (!emailData.recipients.length && this.emailReminderData == undefined) {
        // this.toastrService.warning("Please select one or more contacts to continue.");
        if (this.surveyType == 1) {
          if (!localStorage.getItem("ratee") && this.currentSurvey["ratee"]) {
            this.toastrService.warning(
              "Please provide at least one ratee to send an email.",
              '',
              {
                timeOut: 2000,
              }
            );
            return;
          }
        } else {
          this.toastrService.warning(
            "Please provide at least one contact to send an email.",
            '',
            {
              timeOut: 2000,
            }
          );
          return;
        }
      } else if (
        this.surveyType === 1 &&
        this.emailReminderData === undefined
      ) {
        if (!this.currentSurvey.ratee) {
          let self_obj = {
            email: this.surveyEmailShareForm.value.ratee_email_id,
            first_name: this.surveyEmailShareForm.value.ratee_first_name,
            last_name: this.surveyEmailShareForm.value.ratee_last_name,
            role: 2,
          };
          emailData["isFirst"] = true;
          emailData["self_obj"] = {
            surveyId: this.surveyId,
            ratee: self_obj,
          };
          // localStorage.setItem('ratee' ,JSON.stringify({surveyId : this.surveyId, ratee : self_obj}));

          if (this.isFirstEmailCancelled) {
            let self_index = emailData["recipients"].findIndex((obj: any) => {
              return obj.role == 2;
            });
            if (self_index == -1) {
              emailData["recipients"].push(self_obj);
            }
          }
        }
      }
      this.showSendConfirmationAlertForSaving(emailData);
    }
  }

  public send(isTest: boolean): void {
    if (this.emailBody.indexOf("<p") == -1) {
      this.toastrService.warning("Please fill message.");
      return;
    }
    this.isSubmitted = true;
    let recipientsArray = this.recepients.filter(
      (recepient: any) =>
        (recepient.email !== "" && recepient.first_name !== "") ||
        recepient.last_name !== ""
    );

    let emailData: any = {
      is_test_email: isTest,
      recipients: recipientsArray,
      isFirst: false,
      ...this.surveyEmailShareForm.value,
    };

    emailData["subject"] = this.surveyEmailShareForm.value.subject.replace(
      /{ParticipantName}/g,
      `${this.surveyEmailShareForm.value.ratee_first_name} ${this.surveyEmailShareForm.value.ratee_last_name}`
    );

    if (this.emailReminderData === undefined) {
      let time =
        this.mytime.hour.toString() +
        ":" +
        this.mytime.minute.toString() +
        ":00";
      emailData["survey_expiry_date"] =
        this.expirationDate.year.toString() +
        "-" +
        this.expirationDate.month.toString() +
        "-" +
        this.expirationDate.day.toString();
      if (time) {
        emailData["survey_expiry_date"] =
          emailData["survey_expiry_date"] + "T" + time;
      }
    } else {
      emailData["broadcast"] = true;
      const old_survey_email_ID_type = typeof this.old_survey_email_ID;
      if (old_survey_email_ID_type === "string") {
        emailData["type"] = "batch";
        emailData["old_survey_email_identifier"] = this.old_survey_email_ID;
        // emailData["survey_expiry_date"] = moment(
        //   this.currentSurvey.expiry_date
        // ).format("YYYY-MM-DD");
        emailData["survey_expiry_date"] = this.currentSurvey.expiry_date;
      } else {
        let time =
          this.mytime.hour.toString() +
          ":" +
          this.mytime.minute.toString() +
          ":00";
        emailData["survey_expiry_date"] =
          this.expirationDate.year.toString() +
          "-" +
          this.expirationDate.month.toString() +
          "-" +
          this.expirationDate.day.toString();
        if (time) {
          emailData["survey_expiry_date"] =
            emailData["survey_expiry_date"] + "T" + time;
        }
        emailData["type"] = "all";
      }
    }

    if (isTest) {
      this.isTestSubmitted = true;
      if (
        this.isValidEmail(this.testEmailForm.value["test_email"]) &&
        this.surveyEmailShareForm.valid &&
        this.isValidEmail(this.surveyEmailShareForm.value["reply_to_email"])
      ) {
        emailData.recipients = [
          {
            email: this.testEmailForm.value["test_email"],
            first_name: "",
            last_name: "",
          },
        ];
        this.showSendConfirmationAlert(emailData, isTest);
      }
    } else {
      if (this.surveyEmailShareForm.valid) {
        if (localStorage.getItem("ratee")) {
          let _ratee = JSON.parse(localStorage.getItem("ratee") as string);
          if (_ratee.surveyId == this.route.snapshot.params["id"]) {
            this.toastrService.warning(
              "Participant emails must be distributed before any other rater emails."
            );
            return;
          }
        }

        if (
          !emailData.recipients.length &&
          this.emailReminderData == undefined
        ) {
          // this.toastrService.warning("Please select one or more contacts to continue.");
          if (this.surveyType == 1) {
            if (!localStorage.getItem("ratee") && this.currentSurvey["ratee"]) {
              this.toastrService.warning(
                "Please provide at least one ratee to send an email.",
                '',
                {
                  timeOut: 2000,
                }
              );
              return;
            }
          } else {
            this.toastrService.warning(
              "Please provide at least one contact to send an email.",
              '',
              {
                timeOut: 2000,
              }
            );
            return;
          }
        } else if (
          this.surveyType === 1 &&
          this.emailReminderData === undefined
        ) {
          if (!this.currentSurvey.ratee) {
            let self_obj = {
              email: this.surveyEmailShareForm.value.ratee_email_id,
              first_name: this.surveyEmailShareForm.value.ratee_first_name,
              last_name: this.surveyEmailShareForm.value.ratee_last_name,
              role: 2,
            };
            emailData["isFirst"] = true;
            emailData["self_obj"] = {
              surveyId: this.surveyId,
              ratee: self_obj,
            };
            // localStorage.setItem('ratee' ,JSON.stringify({surveyId : this.surveyId, ratee : self_obj}));

            if (this.isFirstEmailCancelled) {
              let self_index = emailData["recipients"].findIndex((obj: any) => {
                return obj.role == 2;
              });
              if (self_index == -1) {
                emailData["recipients"].push(self_obj);
              }
            }
          }
        }
        this.showSendConfirmationAlert(emailData, isTest);
      }
    }
  }

  public showSendConfirmationAlertForSaving(data: any): void {
    let isUpdate = false;
    if (
      this.currentSurvey.scheduled_email &&
      this.currentSurvey.scheduled_email.launch_at
    ) {
      isUpdate = true;
    }
    this.alertContent = {
      title: "Alert",
      message: `Are you sure that you wish to schedule ${data.recipients && data.recipients.length > 1 ? "" : "an"
        } email to ${data.recipients && data.recipients.length} contact(s)?`,
      data: { emailData: data, surveyId: this.surveyId, isUpdate: isUpdate, project: this.project },
      positiveAction: "saveEmail",
    };

    this.confirmationAlertRef = this.modalService.open(this.confirmationAlert, {
      centered: true,
      backdrop: "static",
      keyboard: false,
      size: "sm",
      windowClass: "modal-holder",
    });

    this.confirmationAlertRef.result.then(
      (result: any) => { },
      (reason: string) => {
        if (reason === "emailSaved") {
          this.confirmationAlertRef.close();
          this.recepients = [];
          this.selected = [];
        }
      }
    );
  }
  public showSendConfirmationAlert(data: any, isTest: boolean): void {
    this.alertContent = {
      title: "Alert",
      message: data.broadcast
        ? `You are about to send ${data.recipients && data.recipients.length > 1 ? "" : "an"
        } email to ${data.recipients && data.recipients.length
        } contact(s). How do you wish to proceed?`
        : `Are you sure to send ${data.recipients && data.recipients.length > 1 ? "" : "an"
        } email to ${data.recipients && data.recipients.length} contact(s)?`,
      data: { emailData: data, surveyId: this.surveyId, isTest, project: this.project },
      positiveAction: "sendEmail",
    };

    this.confirmationAlertRef = this.modalService.open(this.confirmationAlert, {
      centered: true,
      backdrop: "static",
      keyboard: false,
      size: "sm",
      windowClass: "modal-holder",
    });

    this.confirmationAlertRef.result.then(
      (result: any) => { },
      (reason: string) => {
        if (reason === "emailSent") {
          this.confirmationAlertRef.close();
          this.recepients = [];
          this.selected = [];
        }
      }
    );
  }

  public addContacts(): void {
    switch (this.surveyType) {
      case 0:
        for (let i = 0; i < 5; i++) {
          this.recepients.push({
            email: "",
            first_name: "",
            last_name: "",
          });
        }
        break;

      case 1:
        for (let i = 0; i < 5; i++) {
          this.recepients.push({
            email: "",
            first_name: "",
            last_name: "",
            role: null,
          });
        }
        break;

      default:
        break;
    }

    this.recepients = [...this.recepients];
  }

  public resetSheet(): void {
    this.alertContent = {
      title: "Alert",
      message: "Are you sure? All data will be cleared.",
      positiveAction: "resetSheet",
    };

    this.resetSheetConfirmationAlertRef = this.modalService.open(
      this.confirmationAlert,
      {
        centered: true,
        backdrop: "static",
        keyboard: false,
        size: "sm",
        windowClass: "modal-holder resetPopup",
      }
    );
  }

  public saveContacts(): void {
    if (this.emailReminderData == undefined) {
      let fetchedContacts: any = this.recepients.filter((r: any) => {
        if (this.surveyType == 1) {
          return (
            r.first_name.trim() &&
            r.last_name.trim() &&
            r.email.trim() &&
            r.role
          );
        } else {
          return r.first_name.trim() && r.last_name.trim() && r.email.trim();
        }
      });
      let arr_email: any = [];
      fetchedContacts.forEach((item: any) => {
        if (arr_email.indexOf(item.email) == -1) {
          arr_email.push(item.email);
        }
      });
      setTimeout(() => {
        if (arr_email.length != fetchedContacts.length) {
          this.toastrService.warning(
            "Please ensure that each email address is unique.",
            '',
            {
              timeOut: 2000,
            }
          );
        } else {
          if (this.surveyType == 1 && !this.currentSurvey.ratee) {
            let ratee_obj = JSON.parse(JSON.stringify(this.recepients[0]));
            this.surveyEmailShareForm.controls.ratee_email_id.patchValue(
              ratee_obj.email.trim()
            );
            this.surveyEmailShareForm.controls.ratee_first_name.patchValue(
              ratee_obj.first_name.trim()
            );
            this.surveyEmailShareForm.controls.ratee_last_name.patchValue(
              ratee_obj.last_name.trim()
            );
            this.recepients[0] = ratee_obj;
            // this.recepients.splice(0,1);
            // fetchedContacts.splice(0,1);
          }
          if (fetchedContacts.length > 0) {
            let areAllEmailContactsValid = 0;
            fetchedContacts.forEach((c: any) => {
              areAllEmailContactsValid = this.isValidEmail(c.email)
                ? areAllEmailContactsValid + 1
                : areAllEmailContactsValid;
            });

            if (areAllEmailContactsValid == fetchedContacts.length) {
              this.toastrService.success("List is saved successfully.");
              this.getNameForSubject();
              this.contactsModalRef.close();
              this.contacts_modal_visible = false;
            } else {
              this.toastrService.warning(
                "Please validate emails of contacts entered."
              );
            }
          } else {
            if (this.surveyType == 1) {
              this.toastrService.warning(
                "Please provide at least one ratee to send an email.",
                '',
                {
                  timeOut: 2000,
                }
              );
            } else {
              this.toastrService.warning(
                "Please provide at least one contact to send an email.",
                '',
                {
                  timeOut: 2000,
                }
              );
            }
          }
        }
      });
    } else {
      if (this.recepients.length > 0) {
        this.toastrService.success("List is saved successfully.");
        this.contactsModalRef.close();
        this.contacts_modal_visible = false;
        // this.selected = [];
      } else {
        if (this.surveyType == 1) {
          this.toastrService.warning(
            "Please provide at least one ratee to send an email.",
            '',
            {
              timeOut: 2000,
            }
          );
        } else {
          this.toastrService.warning(
            "Please provide at least one contact to send an email.",
            '',
            {
              timeOut: 2000,
            }
          );
        }
      }
    }
  }

  isInvalidRow(row: any) {
    if (
      (!row.first_name.trim() &&
        !row.last_name.trim() &&
        !row.email.trim() &&
        !row.role) ||
      (row.first_name.trim() &&
        row.last_name.trim() &&
        row.email.trim() &&
        (this.surveyType == 1 ? row.role : !row.role))
    ) {
      return false;
    } else {
      return true;
    }
  }


  isInvalidEmail(row: any) {
    let firstName:string = row.first_name.trim();
    let lastName:string = row.last_name.trim();
    let isValidEmail:boolean = row.email.trim() && this.isValidEmail(row.email.trim());

    if(firstName && lastName && !isValidEmail){
      return true;
    }else{
      return false;
    }
  }

  private createForm() {
    this.surveyEmailShareForm = this.formBuilder.group({
      from_email: ["surveyinvite@ambivista.com", Validators.required],
      from_name: ["", [Validators.required]],
      reply_to_email: ["", [Validators.required, Validators.email]],
      subject: ["", [Validators.required, Validators.minLength(10)]],
      message: ["", Validators.required],
      email_template: [""],
      expire_days: [
        "",
        [Validators.required, this.LinkExpiryValidate.bind(this)],
      ],
    });
    this.testEmailForm = this.formBuilder.group({
      test_email: ["", [Validators.required, Validators.email]],
    });
  }

  LinkExpiryValidate(control: AbstractControl) {
    if (control.value && control.value > this.maxLinkDate) {
      return {
        ExpirationValid: "true",
      };
    } else {
      return {};
    }
  }

  public openTestEmailModal(content: any): void {
    this.isSubmitted = true;
    if (this.surveyEmailShareForm.valid) {
      this.testEmailModalRef = this.modalService.open(content, {
        centered: true,
        backdrop: "static",
        keyboard: false,
        windowClass: "modal-holder",
        size: "sm",
      });
    }
  }

  beforeOpeningRecepients: any = [];

  contacts_modal_visible = false;

  public openAddContactsModal(content: any): void {
    if (!this.contacts_modal_visible) {
      this.contacts_modal_visible = true;
      this.contactsModalRef = this.modalService.open(content, {
        centered: true,
        keyboard: false,
        backdrop: "static",
        windowClass: "xlModal",
        size: "lg",
      });
      if (this.emailReminderData == undefined) {
        this.fillSelfToContactModal();
      } else {
        if (!this.selected || !this.selected.length) {
          setTimeout(() => {
            $(".datatable-header-cell-template-wrap .fsop-checkbox").trigger(
              "click"
            );
          });
        }
      }
      this.beforeOpeningRecepients = JSON.parse(
        JSON.stringify(this.recepients)
      );
    }
  }

  subscription = new Subscription();
  listenToColumnAddition() {
    this.subscription.add(
      this.assessmentDataService.columnAdded$.subscribe((data) => {
        if (data && !data.origCol) {
          this.addColumn(data.name);
        } else if (data && data.origCol && data.isDelete) {
          this.removeColumn(data.origCol, data.idx);
        } else if (data && data.origCol) {
          this.editColumnName(data.name, data.idx, data.origCol.name);
        }
        this.closeExpirationModal(true);
      })
    );
  }

  modalReference: any;
  columnObj = {};
  public addColumnName(col?: any, idx?: any): void {
    if (col) {
      delete this.columnObj[col.name];
    }
    this.alertContent = {
      title: col ? "Edit Column Name" : "Add Column Name",
      message: "",
      data: {
        columnObj: this.columnObj,
        col: col ? col : null,
        idx: idx ? idx : 0,
      },
      positiveAction: "createColumn",
    };
    this.modalReference = this.modalService.open(this.modal, {
      centered: true,
      keyboard: false,
      size: "sm",
      backdrop: "static",
      windowClass: "modal-holder",
    });

    this.modalReference.result.then(
      (result: any) => { },
      (reason: string) => {
        if (reason) {
          // this.displayExpDateTime = reason;
          //update display time, expdateand time
        } else {
          //undo change
        }
      }
    );
  }

  modalReferenceDel: any;
  public deleteColumn(col: any, idx: any): void {
    this.alertContent = {
      title: "Delete Column Name",
      message: "Are you sure you want to delete this column?",
      data: { col: col, isDelete: true, idx: idx },
      positiveAction: "deleteColumn",
    };
    this.modalReferenceDel = this.modalService.open(this.modal, {
      centered: true,
      keyboard: false,
      size: "sm",
      backdrop: "static",
      windowClass: "modal-holder",
    });

    this.modalReferenceDel.result.then(
      (result: any) => { },
      (reason: string) => {
        if (reason) {
          // this.displayExpDateTime = reason;
          //update display time, expdateand time
        } else {
          //undo change
        }
      }
    );
  }
  columns: any = [];
  newColumns: any = [];
  addColumn(_name: any) {
    let name = _name.toLowerCase();
    this.columnObj[name] = true;
    let obj = {};
    obj["name"] = name;
    obj["isNew"] = true;
    this.columns.push(obj);
    this.newColumns.push(name);
    this.findRowArray(this._addColumn, name);
  }

  findRowArray(fnc: any, name: any, name2?: any) {
    if (this.emailReminderData != undefined) {
      fnc(this.reminderContacts, name, name2);
    } else if (
      this.emailReminderData == undefined &&
      this.recepients.length > 0 &&
      this.surveyType === 0
    ) {
      fnc(this.recepients, name, name2);
    } else if (
      this.emailReminderData == undefined &&
      this.recepients.length > 0 &&
      this.surveyType === 1
    ) {
      fnc(this.recepients, name, name2);
    }
  }

  _addColumn(arr: any, name: any) {
    arr.forEach((element: any) => {
      element[name] = "";
    });
  }

  removeColumn(col: any, idx: number) {
    if (col) {
      delete this.columnObj[col.name];
    }
    this.findRowArray(this._removeColumn, col.name);
    this.columns.splice(idx, 1);
  }

  _removeColumn(arr: any, name: any) {
    arr.forEach((element: any) => {
      delete element[name];
    });
  }

  editColumnName(newName: any, idx: any, orgName: any) {
    this.columns[idx].name = newName;
    this.findRowArray(this._editColumn, orgName, newName);
  }

  _editColumn(arr: any, orgName: any, newName: any) {
    arr.forEach((element: any) => {
      let val = element[orgName];
      element[newName] = val;
      delete element[orgName];
    });
  }

  removeNewColumns(arr: []) {
    this.newColumns.forEach((nCol: any) => {
      let indx = this.columns.indexOf(nCol);
      if (indx !== -1) {
        this.columns.splice(indx, 1);
        arr.forEach((rec: any) => {
          delete rec.nCol;
        });
      }
    });
  }

  closeExpirationModal(e: boolean) {
    if (this.modalReferenceDel) {
      this.modalReferenceDel.close();
      this.modalReferenceDel = null;
    } else if (this.modalReference) this.modalReference.close();
  }
  cancelSelectModal() {
    this.modalReferenceSelect.close();
  }
  public openBulkUploaderModal(content: any): void {
    this.bulkUploaderModalRef = this.modalService.open(content, {
      centered: true,
      keyboard: false,
      backdrop: "static",
      windowClass: "modal-holder",
      size: "sm",
    });
  }

  public openGuideLinesModal(content: any): void {
    this.guideLinesModalRef = this.modalService.open(content, {
      centered: true,
      keyboard: false,
      backdrop: "static",
      windowClass: "modal-holder",
      size: "sm",
    });
  }

  @Output() emailSent = new EventEmitter();

  onActiveModalClosed($event: any): void {
    if ($event.closeEmitter != undefined) {
      switch ($event.closeEmitter) {
        case "resetSheet":
          this.resetSheetConfirmationAlertRef.close();
          break;
        case "doResetSheet":
          this.resetSheetConfirmationAlertRef.close();
          this.recepients = [];

          switch (this.surveyType) {
            case 0:
              for (let i = 0; i < 10; i++) {
                this.recepients.push({
                  email: "",
                  first_name: "",
                  last_name: "",
                });
              }
              break;

            case 1:
              for (let i = 0; i < 10; i++) {
                this.recepients.push({
                  email: "",
                  first_name: "",
                  last_name: "",
                  role: null,
                });
              }
              // this.fillSelfToContactModal();
              this.recepients[0].role = 2;
              break;

            default:
              break;
          }
          break;
        case "emailCancelled":
          this.confirmationAlertRef.close();
          break;
        case "emailSent":
          this.emailSent.emit(Math.random());
          this.confirmationAlertRef.close();
          this.modalService.dismissAll();
          break;
        case "emailSaved":
          // this.emailSent.emit(Math.random());
          this.confirmationAlertRef.close();
          this.modalService.dismissAll();
          if (this.currentSurvey && this.currentSurvey.status == "scheduled") {
            this.router.navigate(["/campaign-dashboard"]);
          }
          break;
        case "testEmailSent":
          this.confirmationAlertRef.close();
          this.testEmailModalRef.close();
          break;
          break;
        case "reloadRecords":
          this.confirmationAlertRef.close();
          this.reloadContacts();
          break;
        case "deleteRecords":
          this.confirmationAlertRef.close();
          this.selected.forEach((s: any) => {
            this.reminderContacts.splice(this.reminderContacts.indexOf(s), 1);
          });
          this.selected = [];
          break;
        case "deleteRecordsCancelled":
          this.confirmationAlertRef.close();
          break;
        default:
          // code...
          break;
      }
    }
  }

  public closeContactsModal(): void {
    this.contactsModalRef.close();
    this.contacts_modal_visible = false;
    // if (this.emailReminderData != undefined) {
    //   // this.reloadContacts();
    //   // this.selected = [];
    // }else {
    this.recepients = JSON.parse(JSON.stringify(this.beforeOpeningRecepients));
    // }
  }

  reloadRecords(): void {
    this.alertContent = {
      title: "Alert",
      message: "Are you sure to reload all the reminder contacts?",
      positiveAction: "reloadRecords",
    };

    this.confirmationAlertRef = this.modalService.open(this.confirmationAlert, {
      centered: true,
      backdrop: "static",
      keyboard: false,
      size: "sm",
      windowClass: "modal-holder",
    });
  }

  deleteRecords(): void {
    if (this.selected.length > 0) {
      this.alertContent = {
        title: "Alert",
        message: "Are you sure to delete these records?",
        positiveAction: "deleteRecords",
      };

      this.confirmationAlertRef = this.modalService.open(
        this.confirmationAlert,
        {
          centered: true,
          backdrop: "static",
          keyboard: false,
          size: "sm",
          windowClass: "modal-holder",
        }
      );
    } else {
      this.toastrService.warning(
        "No record selected. Please select the record you wish to delete."
      );
    }
  }

  downloadSample(): void {
    this.assessmentDataService
      .downloadContactsSample(this.surveyType)
      .subscribe((response) => {
        var FileSaver = require("file-saver");
        FileSaver.saveAs(
          environment.BASE_URL + response.file_name,
          `Sample.csv`
        );
      });
  }

  uploadBulk(): void {
    document.getElementById("file")?.click();
  }

  getRoleID(role: any) {
    let _index = this.contactRoles.findIndex(
      (data) => data.name.trim().toLowerCase() === role.trim().toLowerCase()
    );

    return _index > -1
      ? this.contactRoles[_index]["id"]
      : role.trim().toLowerCase() == "self"
        ? 2
        : null;
  }

  onBulkUploaderChanged(ev: any): void {
    let receipientsLength: number = this.recepients.length;
    let array: any[] = [];
    //let array : any [] = this.recepients.filter((r: any)=>{ return r.email != undefined || r.first_name != undefined || r.last_name != undefined});
    //let array2 : any [] = [];
    const file: File = ev.target.files.item(0);
    const reader: FileReader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e) => {
      const csv: any = reader.result;
      const parsed = this.papaParse.parse(csv, { header: false });
      // do something with parsed CSV

      if (this.currentSurvey.survey_type === 1) {
        let self_contacts = parsed.data.filter((role: any) => {
          return role[3] && role[3].trim().toLowerCase() == "self";
        });

        if (
          !this.currentSurvey["ratee"] ||
          (this.currentSurvey["ratee"] &&
            !this.currentSurvey["ratee"]["first_name"])
        ) {
          if (self_contacts.length > 1) {
            this.toastrService.warning(
              `File contains more than 1 contact with 'self' role. Please upload file of contacts with just one, or with no 'self' role.`
            );
            return;
          }
          if (
            self_contacts.length == 1 &&
            this.getRoleID(self_contacts[0][3].trim())
          ) {
            this.recepients[0] = {
              first_name: self_contacts[0][0].trim(),
              last_name: self_contacts[0][1].trim(),
              email: self_contacts[0][2].trim(),
              role: this.getRoleID(self_contacts[0][3].trim()),
            };
          }
        }

        if (
          self_contacts.length > 0 &&
          this.currentSurvey["ratee"] &&
          this.currentSurvey["ratee"]["first_name"]
        ) {
          this.toastrService.warning(
            `File contains contacts with 'self' role. Please upload file of contacts with no 'self' role.`
          );
          return;
        }
      }
      this.createNewRolesForBulkUpload(parsed, receipientsLength, array);
    };

    this.bulkUploaderModalRef.close();
  }

  afterNewBulkRolesAdded(parsed: any, receipientsLength: any, array: any) {
    for (var i = 1; i < parsed.data.length; i++) {
      if (this.currentSurvey.survey_type === 0) {
        if (parsed.data[i].length === 3) {
          let obj = {
            first_name: parsed.data[i][0].trim(),
            last_name: parsed.data[i][1].trim(),
            email: parsed.data[i][2].trim(),
          };

          this.recepients.push(obj);
        } else if (parsed.data[i].length > 3) {
          let obj = {
            first_name: parsed.data[i][0].trim(),
            last_name: parsed.data[i][1].trim(),
            email: parsed.data[i][2].trim(),
          };

          obj = this.getCustomColumns(parsed.data, parsed.data[0], 3, obj, i);
          this.recepients.push(obj);
        }
      } else if (this.currentSurvey.survey_type === 1) {
        if (
          parsed.data[i].length === 4 &&
          parsed.data[i][3].toLowerCase() != "self" &&
          this.getRoleID(parsed.data[i][3].trim())
        ) {
          this.recepients.push({
            first_name: parsed.data[i][0].trim(),
            last_name: parsed.data[i][1].trim(),
            email: parsed.data[i][2].trim(),
            role: this.getRoleID(parsed.data[i][3].trim()),
          });
        } else if (
          parsed.data[i].length > 4 &&
          parsed.data[i][3].toLowerCase() != "self" &&
          this.getRoleID(parsed.data[i][3].trim())
        ) {
          var obj = {
            first_name: parsed.data[i][0].trim(),
            last_name: parsed.data[i][1].trim(),
            email: parsed.data[i][2].trim(),
            role: this.getRoleID(parsed.data[i][3].trim()),
          };

          obj = this.getCustomColumns(parsed.data, parsed.data[0], 4, obj, i);
          this.recepients.push(obj);
        }
      }
    }

    if (this.currentSurvey.survey_type === 0) {
      var sortedArray: any[] = this.recepients.filter((r: any) => {
        return (
          r.email.trim() !== "" ||
          r.first_name.trim() !== "" ||
          r.last_name.trim() !== ""
        );
      });

      for (var k = 0; k < receipientsLength - sortedArray.length; k++) {
        array.push({
          first_name: "",
          last_name: "",
          email: "",
        });
      }
      sortedArray = sortedArray.concat(array);
      this.recepients = [...sortedArray];
    } else if (this.currentSurvey.survey_type === 1) {
      var sortedArray: any[] = [];
      sortedArray = this.recepients.filter((r: any) => {
        return (
          r.email.trim() !== "" ||
          r.first_name.trim() !== "" ||
          r.last_name.trim() !== "" ||
          r.role
        );
      });

      for (var k = 0; k < receipientsLength - sortedArray.length; k++) {
        array.push({
          first_name: "",
          last_name: "",
          email: "",
          role: null,
        });
      }
      sortedArray = sortedArray.concat(array);
      this.recepients = [...sortedArray];
    }
  }
  alreadyAdded(arr: any, data: any) {
    var alreadyExist: any = false;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] && arr[i]["name"] && arr[i]["name"] == data) {
        alreadyExist = true;
      }
    }
    return alreadyExist;
  }

  createNewRolesForBulkUpload(parsed: any, receipientsLength: any, array: any) {
    let rArr: any = [];
    if (this.currentSurvey.survey_type === 1) {
      for (var i = 1; i < parsed.data.length; i++) {
        if (
          (parsed.data[i].length === 3 || parsed.data[i].length > 3) &&
          parsed.data[i][3].toLowerCase() != "self" &&
          !this.getRoleID(parsed.data[i][3].trim()) &&
          !this.alreadyAdded(rArr, parsed.data[i][3])
        ) {
          rArr.push({ name: parsed.data[i][3] });
        }
      }

      if (rArr.length) {
        this.assessmentDataService
          .addCustomRole(this.surveyId, rArr)
          .subscribe((data: any) => {
            this.contactRoles = [...this.contactRoles, ...data];
            this.afterNewBulkRolesAdded(parsed, receipientsLength, array);
          });
      } else {
        this.afterNewBulkRolesAdded(parsed, receipientsLength, array);
      }
    } else {
      this.afterNewBulkRolesAdded(parsed, receipientsLength, array);
    }
  }

  getCustomColumns(
    recepientInfo: any,
    col: any,
    allowIndex: number,
    obj: any,
    i: any
  ) {
    col.forEach((element: any, idx: any) => {
      if (idx >= allowIndex) {
        obj[element.toLowerCase()] = recepientInfo[i][idx];

        let a = this.columns.filter((c: any) => {
          return c.name.toLowerCase() == element.toLowerCase();
        });

        if (!a.length) {
          let c1 = {};
          c1["name"] = element.toLowerCase();
          this.columns.push(c1);
        }
      }
    });
    return obj;
  }
  onEmailExpirationChanged(event: any): void {
    if (
      event.key == "0" &&
      !this.surveyEmailShareForm.controls.expire_days.value
    ) {
      this.surveyEmailShareForm.controls.expire_days.setValue("");
    }
  }

  closeComposeEmailModal(): void {
    this.modalService.dismissAll();
    if (!this.emailReminderData) {
      this.router.navigate(["/campaign-dashboard"]);
    }
    // if (this.router.url.includes('builder')) {
    // }
  }

  isValidEmail(email: string): boolean {
    let regex = /^[-!#$%&'*+\/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+\/=?^_`{}|~0-9A-Z]+)*@(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}|[A-Z0-9-]{2,})$/i;
    return regex.test(String(email).toLowerCase());
  }

  get recordsNumber(): number {
    return this.recepients.filter((r: any) => {
      return r.email != "" && this.isValidEmail(r.email);
    }).length;
  }

  changeFormat(id: any, event: any) {
    // setTimeout(() => {
    let text_box = document.getElementById(id);
    if (text_box) {
      text_box["value"] = `${event.month < 9 ? "0" + event.month : event.month
        }-${event.day < 9 ? "0" + event.day : event.day}-${event.year}`;
    }
    // })
  }

  onExpirationDateChanged(date: any): void {
    this.changeFormat("date_picker_format", date);

    setTimeout(() => {
      let expiryDate = moment(
        [
          this.expirationDate.year.toString(),
          this.expirationDate.month.toString(),
          this.expirationDate.day.toString(),
        ],
        "YYYY-MM-DD"
      );

      this.maxLinkDate = expiryDate.diff(this.defaultDate, "days");

      this.surveyEmailShareForm.controls["expire_days"].setValue(
        parseInt(expiryDate.diff(this.defaultDate, "days").toString())
      );
    })
  }

  onEmailBodyChanged(e) {
    this.emailBody = e;
  }


  modalref: any;
  selectedRow: any;
  selectedRole: any;
  public openConfirmModal(modal: any, row: any, role?: any): void {
    this.selectedRow = null;
    this.selectedRole = null;
    if (row && row.role == -1 && !role) {
      this.selectedRow = row;
      this.modalref = this.modalService.open(modal, {
        centered: true,
        keyboard: false,
        backdrop: "static",
        windowClass: "xlModal",
        size: "sm",
      });
      row["role"] = null;
    } else if (role) {
      this.selectedRole = role;
      this.modalref = this.modalService.open(modal, {
        centered: true,
        keyboard: false,
        backdrop: "static",
        windowClass: "xlModal",
        size: "sm",
      });
    }
  }

  public closeCustomeRaterModal(): void {
    this.modalref.close();
  }

  public createCustomRater(obj: any) {
    if (!obj.id) {
      let row = obj.row;
      delete obj["row"];
      this.assessmentDataService.setLoader(true);
      this.assessmentDataService.addCustomRole(this.surveyId, [obj]).subscribe(
        (data: any) => {
          let newRole = data[0];
          this.contactRoles = [...this.contactRoles, newRole];
          this.toastrService.success("New Rater Role added successfully.");
          row["role"] = newRole.id;
          this.assessmentDataService.setLoader(false);
        },
        (error) => {
          row["role"] = null;
          this.toastrService.error(
            error && error.error ? error.error : "Something went wrong."
          );
        }
      );
    } else if (obj.id) {
      this.assessmentDataService.setLoader(true);
      this.assessmentDataService
        .editCustomRole(this.surveyId, obj, obj["id"])
        .subscribe(
          (data: any) => {
            let index = -1;
            this.contactRoles.forEach((crole: any, idx: any) => {
              if (crole["id"] == obj["id"]) {
                index = idx;
                return;
              }
            });
            this.contactRoles[index]["name"] = obj.name;
            let cRole = [...this.contactRoles];
            this.contactRoles = [];
            this.contactRoles = cRole;
            this.toastrService.success("Respondent role updated successfully.");
            this.assessmentDataService.setLoader(false);
          },
          (error) => {
            // row["role"] = null;
            this.toastrService.error(
              error && error.error && error.error.error_msg
                ? error.error.error_msg
                : "Something went wrong."
            );
          }
        );
    }
  }

  delete(id: any) {
    this.assessmentDataService.deleteCustomRole(this.surveyId, id).subscribe(
      (obj: any) => {
        let index = -1;
        this.contactRoles.forEach((crole: any, idx: any) => {
          if (crole["id"] == id) {
            index = idx;
            return;
          }
        });
        this.contactRoles.splice(index, 1);
        let cRole = [...this.contactRoles];
        this.contactRoles = cRole;
        this.updatEditedRoleName(id);
        this.toastrService.success("Custom Role deleted successfully.");
      },
      (error) => {
        if (error && error.error && error.error.message) {
          this.toastrService.error(error.error.message);
        } else {
          this.toastrService.error("Something went wrong.");
        }
      }
    );
  }

  updatEditedRoleName(id: any) {
    if (this.emailReminderData != undefined) {
      updateRoleName(this.reminderContacts, id);
    } else if (
      this.emailReminderData == undefined &&
      this.recepients.length > 0 &&
      this.surveyType === 1
    ) {
      updateRoleName(this.recepients, id);
    }

    function updateRoleName(arr: any, id: any) {
      arr.forEach((el: any) => {
        if (el && el.role == id) {
          el["role"] = null;
        }
      });
    }
  }

  modalref2: any;
  public openDeleteModal(modal: any, role: any): void {
    this.selectedRole = role;
    this.modalref2 = this.modalService.open(modal, {
      centered: true,
      keyboard: false,
      backdrop: "static",
      windowClass: "xlModal",
      size: "sm",
    });
  }

  public closeDeleteModal(): void {
    this.modalref2.close();
  }

  get isMultirater(){
    return this.surveyType == 1
  }
}
