import { Injectable } from "@angular/core";
import { Observable, of, concat, Subject, BehaviorSubject } from "rxjs";
import { HttpService } from "./http/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Page } from "../models/data-table-page.model";
import { SurveyFilter } from "../models/survey-filter-model";

import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { map } from "rxjs/internal/operators/map";
import { ISurveySort } from "../models/survey-sort.model";
import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";
import { ISpellCheck } from "../models/spellCheck.model";
import { Project } from "../models/project.model";
import { EmailTemplateResponse } from "../models/email-template.model";
import { DataManagementColumnFields } from "../models/data-mgmt-fields.model";

@Injectable({
  providedIn: "root",
})
export class AssessmentDataService {
  onSurveyDesignUpdated$ = new Subject();
  constructor(
    private httpService: HttpService,
    private httpClient: HttpClient,
    private router: Router
  ) {}
  public settings: any;
  public countryList: any = [];
  public loaderSubject: Subject<any> = new Subject();
  public positionUpdateSubject: Subject<any> = new Subject();
  public headerUpdates$: Subject<any> = new Subject();
  public dateUpdate$: Subject<any> = new Subject();
  public columnAdded$: Subject<any> = new Subject();
  public expiryDateUpdated$: Subject<any> = new Subject();
  public customRoleDeleted$: Subject<any> = new Subject();
  public displayLogicAdded$: Subject<any> = new Subject();
  public newDisplayLogicAdded$: Subject<any> = new Subject();
  public deleteDisplayLogic$: Subject<any> = new Subject();
  public skipLogicAdded$: Subject<any> = new Subject();
  public branchLogicAdded$: Subject<any> = new Subject();
  public optionChoosen$: Subject<any> = new Subject();
  public sectionNameUpdated$: Subject<any> = new Subject();
  public surveyResponseUpdated$: Subject<any> = new Subject();
  public focusAnswerOkClicked$: Subject<any> = new Subject();
  public updateEmbedDataSubject$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  setLoader(val: any) {
    this.loaderSubject.next(val);
  }

  startLoader(){
    this.setLoader({bool:true})
  }

  stopLoader(){
    this.setLoader({bool:false})
  }

  setCorrectPositions(val: any) {
    this.positionUpdateSubject.next(val);
  }

  public getSurveyDisplayLogics(surveyId:string){
    return this.httpService.get(
      environment.BASE_URL +
        `surveys/${surveyId}/display-logics/`
    );
  }

  public updateInsectionMovement(surveyId: any, body: any): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL +
        `surveys/${surveyId}/move-entity/insection/?return_ques_order=true`,
      body
    );
  }

  public updateInsectionPageBreakMovement(
    url: any,
    body: any
  ): Observable<any> {
    return this.httpService.put(url, body);
  }

  public updateOutsectionMovement(surveyId: any, body: any): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL +
        `surveys/${surveyId}/move-entity/outsection/?return_ques_order=true`,
      body
    );
  }

  public checkEmailLink(survey_email_link_id: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL +
        `suvery-emails/${survey_email_link_id}/check-email-link/`
    );
  }

  public createSurvey(survey: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + "surveys/", survey);
  }

  public getSurvey(surveyId: string): Observable<any> {
    return this.httpService.get(environment.BASE_URL + `surveys/${surveyId}/`);
  }

  public getOneProject(projectId: string): Observable<Project> {
    return this.httpService.get(environment.BASE_URL + `projects/${projectId}/`) as Observable<Project>;
  }

  public getBackgroundImagesUrl(): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `surveys/setting/theme_default_images/`
    );
  }

  public getSurveyStatus(surveyId: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `surveys/${surveyId}/check_survey_status/`
    );
  }

  public checkSaveandResume(survey_email_link_id: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `surveys/${survey_email_link_id}/preview/`
    );
  }

  public getSurveyPreview(surveyId: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `surveys/${surveyId}/preview/`
    );
  }

  public getParicularResponseData(surveyId: any, responseId: any) {
    return this.httpService.get(
      environment.BASE_URL + `surveys/${surveyId}/responses/${responseId}/`
    );
  }

  public get360PdfReport(type: number, surveyId: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `report/survey/${surveyId}/?code=${type}`
    );
  }

  getInitialTemplates(surveyType: number, pageNo: any) {
    let queryStatement = `exclude=questions&fields=title&fields=created_by__email&fields=company__name&fields=created_by__first_name&fields=created_by__last_name&fields=organization__name&is_deleted=False&manage=True&nested=author&page=${pageNo}&ordering=-modified_at&is_template=true`;
    queryStatement += `&survey_type=${surveyType}`;
    return this.httpService.get(
      environment.BASE_URL + `surveys/?${queryStatement}`
    );
  }

  public getAssessments(
    pageObj: Page,
    filter: any,
    forLisingInGroup?: string,
    sort?: ISurveySort
  ): Observable<any> {
    let queryStatement = `&fields=title&fields=created_by__email&fields=company__name&fields=created_by__first_name&fields=created_by__last_name&fields=organization__name&is_deleted=False&manage=True&nested=author&page=${
      pageObj.pageNumber == 0 ? 1 : pageObj.pageNumber
    }&page_size=${pageObj.size}`;

    if (filter.is_dashboard) {
      queryStatement = !forLisingInGroup
        ? `dashboard=${filter.is_dashboard}`.concat(queryStatement)
        : "group=true".concat(queryStatement);
    } else {
      queryStatement = "exclude=questions".concat(queryStatement);
    }

    if (!sort) {
      queryStatement +=
        filter.ordered_by !== null ? `&ordering=-${filter.ordered_by}` : "";
    } else if (sort) {
      switch (sort.type) {
        case "asc":
          queryStatement += `&ordering=${sort.ordering}`;
          break;

        case "desc":
          queryStatement += `&ordering=-${sort.ordering}`;
          break;

        default:
          break;
      }
    }

    let query = {}


    queryStatement +=
      filter.period.replace(/\s/g, "").toLowerCase() !== "allperiods"
        ? `&modified_at=${filter.period.replace(/\s/g, "").toLowerCase()}`
        : "";
    queryStatement +=
      filter.status.replace(/\s/g, "").toLowerCase() !== "allsurveys"
        ? `&status=${filter.status.replace(/\s/g, "").toLowerCase()}`
        : "";
    queryStatement += filter.modified_at_gt
      ? `&modified_at_gt=${filter.modified_at_gt}`
      : "";
    queryStatement += filter.modified_at_lt
      ? `&modified_at_lt=${filter.modified_at_lt}`
      : "";
    queryStatement +=
      !filter.is_template && filter.search !== null && filter.search !== ""
        ? `&search=${filter.search}`
        : "";
    queryStatement +=
      filter.is_template && filter.search !== null && filter.search !== ""
        ? `&template_name=${filter.search}`
        : "";
    queryStatement +=
      filter.is_template !== undefined
        ? `&is_template=${filter.is_template}`
        : "";
    queryStatement +=
      filter.survey_type !== undefined
        ? `&survey_type=${filter.survey_type}`
        : "";

    if (forLisingInGroup !== null) {
      queryStatement += forLisingInGroup ? forLisingInGroup : "";
    } else {
      queryStatement +=
        filter.survey_type !== undefined
          ? `&survey_type=${filter.survey_type}`
          : "";
    }

    return this.httpService.get(
      environment.BASE_URL + `projects/?${queryStatement}`
    );
  }
  public getSurveyAssessments(
    pageObj: Page,
    filter: any,
    forLisingInGroup?: string,
    sort?: ISurveySort
  ): Observable<any> {
    let queryStatement = `&fields=title&fields=created_by__email&fields=company__name&fields=created_by__first_name&fields=created_by__last_name&fields=organization__name&is_deleted=False&manage=True&nested=author&page=${
      pageObj.pageNumber == 0 ? 1 : pageObj.pageNumber
    }&page_size=${pageObj.size}`;

    if (filter.is_dashboard) {
      queryStatement = !forLisingInGroup
        ? `dashboard=${filter.is_dashboard}`.concat(queryStatement)
        : "group=true".concat(queryStatement);
    } else {
      queryStatement = "exclude=questions".concat(queryStatement);
    }

    if (!sort) {
      queryStatement +=
        filter.ordered_by !== null ? `&ordering=-${filter.ordered_by}` : "";
    } else if (sort) {
      switch (sort.type) {
        case "asc":
          queryStatement += `&ordering=${sort.ordering}`;
          break;

        case "desc":
          queryStatement += `&ordering=-${sort.ordering}`;
          break;

        default:
          break;
      }
    }

    let query = {}


    queryStatement +=
      filter.period.replace(/\s/g, "").toLowerCase() !== "allperiods"
        ? `&modified_at=${filter.period.replace(/\s/g, "").toLowerCase()}`
        : "";
    queryStatement +=
      filter.status.replace(/\s/g, "").toLowerCase() !== "allsurveys"
        ? `&status=${filter.status.replace(/\s/g, "").toLowerCase()}`
        : "";
    queryStatement += filter.modified_at_gt
      ? `&modified_at_gt=${filter.modified_at_gt}`
      : "";
    queryStatement += filter.modified_at_lt
      ? `&modified_at_lt=${filter.modified_at_lt}`
      : "";
    queryStatement +=
      !filter.is_template && filter.search !== null && filter.search !== ""
        ? `&search=${filter.search}`
        : "";
    queryStatement +=
      filter.is_template && filter.search !== null && filter.search !== ""
        ? `&template_name=${filter.search}`
        : "";
    queryStatement +=
      filter.is_template !== undefined
        ? `&is_template=${filter.is_template}`
        : "";
    queryStatement +=
      filter.survey_type !== undefined
        ? `&survey_type=${filter.survey_type}`
        : "";

    if (forLisingInGroup !== null) {
      queryStatement += forLisingInGroup ? forLisingInGroup : "";
    } else {
      queryStatement +=
        filter.survey_type !== undefined
          ? `&survey_type=${filter.survey_type}`
          : "";
    }

    return this.httpService.get(
      environment.BASE_URL + `surveys/?${queryStatement}`
    );
  }


  public getProjects(
    queryParamObj: any
  ): Observable<any> {



    return this.httpService.get(
      environment.BASE_URL + `projects/`,{ params:{...queryParamObj}}
    );
  }



  public getSurveysInProject(projectId:string,queryParamObj: any): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `projects/${projectId}/surveys/`,{ params:{...queryParamObj}}
    );
  }

  public getGroupAssessments(
    pageObj: Page,
    group_id: any,
    group_service_status: any,
    group_query: any,
    sort: ISurveySort,
    search: string
  ): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL +
        `survey-groups/survey/?page=${pageObj.pageNumber}&page_size=${
          pageObj.size
        }&${group_service_status}${
          group_id ? "&group_id=" + group_id : ""
        }${group_query}&search=${search}${
          sort && sort.ordering
            ? "&ordering=" + (sort.type == "desc" ? "-" : "") + sort.ordering
            : ""
        }`
    );
  }

  getIndividualSurveys(email: any, page: Page, start?: any, end?: any) {
    if (!email && start && end) {
      return this.httpService.get(
        environment.BASE_URL +
          `delete-response/?page=${page.pageNumber}&page_size=${page.size}&end_date=${end}&start_date=${start}`
      );
    }
    return this.httpService.get(
      environment.BASE_URL +
        `delete-response/?email=${encodeURIComponent(email)}&page=${
          page.pageNumber
        }&page_size=${page.size}`
    );
  }

  deleteIndividualsSurveyData(body: any) {
    return this.httpService.post(
      environment.BASE_URL + `delete-response/`,
      body
    );
  }

  checkIfSurveyLaunched(survey_id: any): Observable<any> {
    return this.httpService
      .get(environment.BASE_URL + `surveys/${survey_id}/`)
      .pipe(
        map((response:any) => {
          //no need to check for templates
          if (response["is_template"]) {
            return true;
          }

          if (response["status"] && response["status"] == "unlaunched") {
            return true;
          } else {
            this.router.navigate(["dashboard"]);
            return false;
          }
        })
      );
  }

  public getAssessmentsByStatus(
    status: string,
    isDashboard?: boolean
  ): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL +
        `surveys/?dashboard=${isDashboard}&status=${status}&fields=title&fields=created_by__email&fields=created_by__first_name&fields=created_by__last_name&fields=organization__name&is_deleted=False&manage=True&nested=author&ordering=-modified_at`
    );
  }

  public getAssessmentsByPeriod(
    period: string,
    isDashboard?: boolean
  ): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL +
        `surveys/?dashboard=${isDashboard}&modified_at=${period}&fields=title&fields=created_by__email&fields=created_by__first_name&fields=created_by__last_name&fields=organization__name&is_deleted=False&manage=True&nested=author&ordering=-modified_at`
    );
  }

  public getQuestionByUrl(q_url: string): Observable<any> {
    return this.httpService.get(q_url);
  }

  public filterAssessments(
    period: string,
    status: string,
    fromdate: string,
    todate: string,
    isDashboard?: boolean
  ): Observable<any> {
    //&modified_at=${period}&status=${status}
    let preparedQuery: string = `?dashboard=${isDashboard}&fields=title&fields=created_by__email&fields=created_by__first_name&fields=created_by__last_name&fields=organization__name&is_deleted=False&manage=True&nested=author&ordering=-modified_at`;
    preparedQuery += period !== "allperiods" ? `&modified_at=${period}` : "";
    preparedQuery += status !== "allsurveys" ? `&status=${status}` : "";
    preparedQuery += fromdate != undefined ? `&modified_at_gt=${fromdate}` : "";
    preparedQuery += todate != undefined ? `&modified_at_lt=${todate}` : "";
    return this.httpService.get(
      environment.BASE_URL + `surveys/${preparedQuery}`
    );
  }

  public getSurveyDashboardByPeriod(
    surveyId: string,
    period: string
  ): Observable<any> {
    let preparedQuery: string = "";
    preparedQuery += period !== "allperiods" ? `?date=${period}` : "";
    /*preparedQuery+= fromDate != undefined ? `&start_date=${fromDate}` : '';
    preparedQuery+= toDate != undefined  ? `&end_date=${toDate}` : '';*/
    return this.httpService.get(
      environment.BASE_URL + `surveys/${surveyId}/dashboard/${preparedQuery}`
    );
  }

  public getSurveyDashboardByPeriodRange(
    surveyId: string,
    period: string,
    start_date: string,
    end_date: string
  ): Observable<any> {
    let preparedQuery: string = "";
    preparedQuery += period !== "allperiods" ? `?date=${period}` : "";
    preparedQuery += start_date != undefined ? `&start_date=${start_date}` : "";
    preparedQuery += end_date != undefined ? `&end_date=${end_date}` : "";
    return this.httpService.get(
      environment.BASE_URL + `surveys/${surveyId}/dashboard/${preparedQuery}`
    );
  }

  public getDeletedAssessments(
    pageObj: Page,
    filter: any,
    sort?: ISurveySort
  ): Observable<any> {
    let preparedStatement: string = `projects/?is_deleted=True&page=${pageObj.pageNumber}&page_size=${pageObj.size}`;

    preparedStatement +=
      filter.period.replace(/\s/g, "").toLowerCase() !== "allperiods"
        ? `&modified_at=${filter.period.replace(/\s/g, "").toLowerCase()}`
        : "";
    preparedStatement +=
      filter.search !== null && filter.search !== ""
        ? `&search=${filter.search}`
        : "";
    preparedStatement +=
      filter.modified_at_gt !== null
        ? `&modified_at_gt=${filter.modified_at_gt}`
        : "";
    preparedStatement +=
      filter.modified_at_lt !== null
        ? `&modified_at_lt=${filter.modified_at_lt}`
        : "";

    if (!sort) {
      preparedStatement +=
        filter.ordered_by !== null ? `&ordering=-${filter.ordered_by}` : "";
    } else if (sort) {
      switch (sort.type) {
        case "asc":
          preparedStatement += `&ordering=${sort.ordering}`;
          break;

        case "desc":
          preparedStatement += `&ordering=-${sort.ordering}`;
          break;

        default:
          break;
      }
    }

    return this.httpService.get(environment.BASE_URL + preparedStatement);
  }

  public customFilterDeletedAssessmentsByPeriod(
    fromDate: string,
    toDate: string
  ): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL +
        `surveys/?is_deleted=True&modified_at=custom&modified_at_gt=${fromDate}&modified_at_lt=${toDate}`
    );
  }

  public uploadData(data: string): Observable<any> {
    return this.httpService.post(environment.BASE_URL + `upload_data/`, {
      file: data,
    });
  }

  public deleteSurvey(surveyId: string): Observable<any> {
    return this.httpService.delete(
      environment.BASE_URL + "surveys/" + surveyId + "/"
    );
  }

  public deleteProject(projectId: string): Observable<any> {
    return this.httpService.delete(
      environment.BASE_URL + "projects/" + projectId + "/"
    );
  }

  public deleteMultipleSurveys(project_ids: any): Observable<any> {
    return this.httpService.delete(
      environment.BASE_URL + "projects/delete_multiple_projects/",
      {
        body: { project_ids: project_ids },
      } as any
    );
  }

  public updateSurveyExpiration(
    surveyId: string,
    date: string
  ): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL + "surveys/" + surveyId + "/expiry_update/",
      { value: date }
    );
  }

  public updateProjectExpiration(
    projectId: string,
    date: string
  ): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL + "projects/" + projectId + "/launch_update/",
      { launch_at: date }
    );
  }

  public updateSurveyScope(survey: any, scope: number): Observable<any> {
    return this.httpService.patch(
      environment.BASE_URL + `surveys/${survey.identifier}/`,
      {
        survey_scope: scope,
        title: survey.title,
        company: survey.organization__name,
      } as any
    );
  }

  public restoreSurvey(projects: string[]): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + "projects/restore_projects/",
      { projects: projects }
    );
  }

  public createSection(section: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + "sections/", section);
  }

  public updateSection(sectionUrl: string, section: any): Observable<any> {
    return this.httpService.put(sectionUrl, section);
  }

  public editSurveyName(body: any, surveyId: string): Observable<any> {
    return this.httpService.patch(
      environment.BASE_URL + `surveys/${surveyId}/`,
      body
    );
  }

  public getContactsRoles2(id: any): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `surveys/${id}/contact-role/?page_size=100`
    );

    // return this.httpService.get(
    //   environment.BASE_URL + `surveys/contact-role/?page_size=100`
    // );
  }

  public getContactsRoles(): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `surveys/contact-role/?page_size=100`
    );
  }

  public addCustomRole(sId: any, data: any): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + `surveys/${sId}/contact-role/`,
      data
    );
  }

  public editCustomRole(sId: any, data: any, roleId: any): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL + `surveys/${sId}/contact-role/${roleId}/`,
      data
    );
  }

  public deleteCustomRole(sId: any, roleId: any) {
    return this.httpService.delete(
      `${environment.BASE_URL}surveys/${sId}/contact-role/${roleId}/`
    );
  }

  public addQuestion(question: any): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + "surveyquestions/",
      question
    );
  }

  public removeQuestion(
    questions: string[],
    permanent?: boolean
  ): Observable<any> {
    if (permanent != undefined) {
      return this.httpService.delete(
        environment.BASE_URL +
          `surveyquestions/deletequestion/?permanent=${permanent}`,
        {
          body: { questions },
        } as any
      );
    } else {
      return this.httpService.delete(
        environment.BASE_URL + `surveyquestions/deletequestion/`,
        {
          body: { questions },
        } as any
      );
    }
  }

  public deleteSection(sectionUrl: string): Observable<any> {
    return this.httpService.delete(sectionUrl);
  }

  public saveQuestion(question: any): Observable<any> {
    if (typeof question.is_deleted != "undefined") {
      delete question.is_deleted;
    }

    if (question.text.toLowerCase().includes("</html>")) {
      let text:any  = /<body.*?>([\s\S]*)<\/body>/.exec(question.text);
      question.text = text[1];
    }

    // question['text'] = question['text']
    //   .replace(/&amp;/g, "&")
    //   .replace(/'&nbsp;/g, "'")
    //   .replace(/\*&nbsp;/g, "*")
    //   .replace(/`&nbsp;/g, "`")
    //   .replace(/\$&nbsp;/g, "$")
    //   .replace(/&lt;/g, "<")
    //   .replace(/&gt;/g, ">");
    return this.httpService.put(question.url, question);
  }

  public addPageBreak(pageBreak: any): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + "pagebrakes/",
      pageBreak
    );
  }

  public removePageBreak(pageBreakUrl: string): Observable<any> {
    return this.httpService.delete(pageBreakUrl);
  }

  public getSurveyDashboard(surveyId: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `surveys/${surveyId}/dashboard/`
    );
  }

  public getProjectDashboard(projectId: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `projects/${projectId}/dashboard/`
    );
  }

  public getSurveyEmailDashboard(surveyId: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `surveys/${surveyId}/survey-emails-analytics/`
    );
  }

  public publishSurveyByEmail(
    surveyId: string,
    emailData: any
  ): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + `surveys/${surveyId}/send_email/`,
      emailData
    );
  }

  public publishProjectByEmail(
    projectId: string,
    emailData: any
  ): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + `projects/${projectId}/schedule/`,
      emailData
    );
  }

  public updateSurveySetting(surveyId: string, setting: any): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL + `surveys/${surveyId}/setting/`,
      setting
    );
  }

  public patchSurveySetting(surveyId: string, setting: any): Observable<any> {
    return this.httpService.patch(
      environment.BASE_URL + `surveys/${surveyId}/setting/`,
      setting
    );
  }

  public getSurveySetting(surveyId: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `surveys/${surveyId}/setting/`);
  }

  public saveEmbedData(surveyId: string, embedData: any){
    let body = embedData.filter((eb:any)=>eb.name);
    return this.httpService.post(
      environment.BASE_URL + `surveys/${surveyId}/embed-data/`,
      body
    );
  }

  public updateSurveyDesign(surveyId: string, design: any): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL + `surveys/${surveyId}/setting/?type=design`,
      design
    );
  }

  public updateSurveyCustomDesign(
    surveyId: string,
    design: any
  ): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL + `surveys/${surveyId}/setting/?type=custom_design`,
      {
        custom_design_objects: design,
      }
    );
  }

  public checkSurveySpelling(suveyId: string): Observable<ISpellCheck[]> {
    return this.httpService.get(
      environment.BASE_URL + `surveys/${suveyId}/spell-check/?page_size=10`
    ) as Observable<ISpellCheck[]>;
  }

  public getSurveyQuestions(surveyId: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `surveys/${surveyId}/get-questions/`
    );
  }

  public getQuestionReport(questionUrl: string): Observable<any> {
    return this.httpService.get(questionUrl + "report/");
  }

  public getSurveyReport(surveyId: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `surveys/${surveyId}/report/`
    );
  }

  public getSurveyReportExportDataLink(
    surveyId: string,
    tab: string
  ):any {
    if (tab === "Survey Analytics") {
      return this.httpService.get(
        environment.BASE_URL + `surveys/${surveyId}/csv-report/`
      );
    } else if (tab === "Campaign Reports") {
      return this.httpService.get(
        environment.BASE_URL + `individual-survey-report/${surveyId}/`
      );
    }
  }

  getCampaignReport(sId: any) {
    return this.httpService.get(
      environment.BASE_URL + `surveys/${sId}/campaign-report/`
    );
  }

  public getSurveyContactsCsv(surveyId: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL +
        `surveys/${surveyId}/csv-report/?contacts_only=true`
    );
  }

  public createAssessmentResponse(response: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + "responses/", response);
  }

  public updateAssessmentResponseStatus(
    responseUrl: string,
    response: any
  ): Observable<any> {
    return this.httpService.put(responseUrl, response);
  }

  public launchSurvey(surveyId: string, survey: any): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL + `surveys/${surveyId}/launch/`,
      survey
    );
  }

  public launchSurveyUsingProject(projectIdentifier: string, survey: any): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL + `projects/${projectIdentifier}/launch/`,
      survey
    );
  }

  public cancelScheduledSurvey(surveyId: string, survey: any): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL + `surveys/${surveyId}/cancel/`,
      survey
    );
  }

  public scheduleLaunchSurvey(surveyId: string, survey: any): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL + `surveys/${surveyId}/launch_update/`,
      survey
    );
  }

  public deactivateSurvey(surveyId: string, survey: any): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL + `surveys/${surveyId}/inactive/`,
      survey
    );
  }

  public getSurveyResponse(responseUrl: string): Observable<any> {
    return this.httpService.get(responseUrl + "response/");
  }
  public getSurveyResponseAgainstRtToken(url): Observable<any> {
    return this.httpService.get(url);
  }

  public copySurveyWithAttributeUpdates(
    surveyId: string,
    body: any,
    surveyTitle: any
  ): Observable<any> {
    body = { ...body, ...{ title: surveyTitle } };
    return this.httpService.post(
      environment.BASE_URL + `surveys/${surveyId}/copy-survey/`,
      body
    );
  }

  public copyProjectWithAttributeUpdates(
    projectId: string,
    body: any,
    name: any
  ): Observable<any> {
    body = { ...body, ...{ name } };
    return this.httpService.post(
      environment.BASE_URL + `projects/${projectId}/copy/`,
      body
    );
  }

  public updatePostLaunchSurveyAttribute(
    surveyId: string,
    body: any
  ): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + `surveys/${surveyId}/attributes/`,
      body
    );
  }

  public updatePostLaunchProjectAttribute(
    projectId: string,
    body: any
  ): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + `projects/${projectId}/attributes/`,
      body
    );
  }

  public copySurvey(surveyId: string, title: string): Observable<any> {
    let body = { template_name: title };
    return this.httpService.post(
      environment.BASE_URL + `surveys/${surveyId}/copy-survey/`,
      body
    );
  }

  public downloadNormativeData(surveyId: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `surveys/${surveyId}/normative_data/`
    );
  }

  public deleteNormativeData(_id: string, groups?: any): Observable<any> {
    return this.httpService.delete(
      environment.BASE_URL +
        `${groups ? "survey-groups" : "surveys"}/${_id}/delete_normative_data/`
    );
  }

  public importCsv(
    normative_data: any,
    questionURL?: string,
    surveyId?: string
  ): Observable<any> {
    let url: string;
    if (surveyId) {
      url = environment.BASE_URL + `surveys/${surveyId}/normative_data/`;
      return this.httpClient.post(
        url,
        { data: normative_data },
        { headers: { Type: "application/json" } }
      );
    } else {
      url = questionURL + "choice-import/";
      // const formData: FormData = new FormData();
      // formData.append("csv_file", normative_data, normative_data.name);
      return this.httpClient.post(url, normative_data, {
        headers: { Type: "application" },
      });
    }
  }
  public importBlackistedEmailsCsv(
    blacklisted_data: any,
    userId: any
  ): any {
    let url: string;
    if (userId) {
      url =
        environment.BASE_URL + `standard-users/${userId}/blacklist_domains/`;
      return this.httpClient.post(url, blacklisted_data, {
        headers: { Type: "application/json" },
      });
    }
  }

  public importGroupCsv(normative_data: any, groupId: string): Observable<any> {
    let url = environment.BASE_URL + `survey-groups/${groupId}/normative_data/`;
    return this.httpClient.post(
      url,
      { data: normative_data },
      { headers: { Type: "application/json" } }
    );
  }

  public downloadGroupCsv(groupId: string): Observable<any> {
    let url = environment.BASE_URL + `survey-groups/${groupId}/normative_data/`;
    return this.httpClient.get(url);
  }

  public downloadBlacklistedEmailCsv(userId: string): Observable<any> {
    let url =
      environment.BASE_URL + `standard-users/${userId}/blacklist_domains/`;
    return this.httpClient.get(url);
  }

  public submitSurveyAnswers(
    responseUrl: string,
    currentPage: number,
    maxPage: number,
    answers: any[]
  ): Observable<any> {
    return this.httpService.post(
      responseUrl + `answers/?current_page=${currentPage}&max_page=${maxPage}`,
      answers
    );
  }

  public getAutoChoices(): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + "autochoice/?page_size=10"
    );
  }
  public moveQuestion(
    surveyId: string,
    newPositionObject: any
  ): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL +
        `surveys/${surveyId}/move-questions/?return_ques_order=true`,
      newPositionObject
    );
  }

  public copyQuestion(surveyId: string, quesionObj: any): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + `surveys/${surveyId}/copy-questions/`,
      quesionObj
    );
  }

  public getSurveyTrash(surveyId: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `surveys/${surveyId}/trash/`
    );
  }

  public restoreQuestions(questions: string[]): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL + `surveyquestions/restore/`,
      { questions: questions }
    );
  }

  public revokeEmailRequest(taskid: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `surveys/revoke/${taskid}/`
    );
  }

  public getEmailAnalytics(surveyId: string, page: Page): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL +
        `surveys/${surveyId}/survey-emails-analytics/?page=${page.pageNumber}&page_size=${page.size}`
    );
  }

  public getEmailReminderAnalytics(
    emailID?: string,
    nextURL?: string
  ): Observable<any> {
    if (emailID != null) {
      return this.httpService.get(
        environment.BASE_URL +
          `suvery-emails/${emailID}/reminder-emails-analytics/?page_size=5`
      );
    } else {
      return this.httpService.get(`${nextURL}`);
    }
  }

  public getScheduledEmailRemainders(
    emailID?: string
  ): any {
    if(emailID)
      return this.httpService.get(
        environment.BASE_URL +
          `survey_emails/${emailID}/schedule-reminder-emails/`
      );
  }

  public getScheduledEmailRemaindersToAll(emailID?: string){
    return this.httpService.get(
      environment.BASE_URL +
        `surveys/${emailID}/schedule-reminder-emails/`
    );
  }

  public deleteScheduledReminder(
    id?: string
  ): Observable<any> {
      return this.httpService.delete(
        environment.BASE_URL +
          `survey_emails/${id}/schedule-reminder-emails/`
      );
  }

  public deleteScheduledReminderAll(
    id?: string
  ): Observable<any> {
      return this.httpService.delete(
        environment.BASE_URL +
          `surveys/${id}/schedule-reminder-emails/`
      );
  }

  public getReportSvg(
    file: string,
    survey_identifier: string,
    tab: string
  ): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + `svg_pdf/?survey_identifier=${survey_identifier}`,
      { file }
    );
  }

  public getReminderContacts(survey_email_id: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL +
        `resend-email-contacts/?page_size=10&survey_email_identifier=${survey_email_id}`
    );
  }

  public getAllReminderContacts(survey_id: string): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `surveys/${survey_id}/reminder-email-contacts/`
    );
  }

  public getSurveyContacts(
    survey_email_id: string,
    search_parameters: any,
    pageObj: Page,
    sort: ISurveySort
  ): Observable<any> {
    let preparedUrl =
      environment.BASE_URL +
      `survey-email/${survey_email_id}/contacts/?page=${pageObj.pageNumber}&page_size=${pageObj.size}`;
    preparedUrl += search_parameters.search
      ? `&search=${search_parameters.search}`
      : "&search=";
    preparedUrl += search_parameters.role_id
      ? `&role=${search_parameters.role_id}`
      : "";
    preparedUrl += search_parameters.email_status
      ? `&survey_email_activities__status=${search_parameters.email_status}`
      : "";
    preparedUrl += search_parameters.survey_status
      ? `&response__status=${search_parameters.survey_status}`
      : "";

    if (sort) {
      switch (sort.type) {
        case "asc":
          preparedUrl += `&ordering=${sort.ordering}`;
          break;

        case "desc":
          preparedUrl += `&ordering=-${sort.ordering}`;
          break;

        default:
          break;
      }
    }

    return this.httpService.get(preparedUrl);
  }

  public deleteContact(
    survey_email_id: string,
    contact_id: string
  ): Observable<any> {
    return this.httpService.delete(
      environment.BASE_URL +
        `survey-email/${survey_email_id}/contacts/${contact_id}/`
    );
  }

  public updateContact(
    survey_email_id: string,
    contact_id: string,
    contact: any
  ): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL +
        `survey-email/${survey_email_id}/contacts/${contact_id}/`,
      contact
    );
  }

  public resendEmailToContact(
    survey_email_id: string,
    contact_id: string,
    contact: any
  ): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL +
        `survey-email/${survey_email_id}/contacts/${contact_id}/resend_email/`,
      contact
    );
  }

  public sendReminderToAll(surveyId: string, emailData: any): Observable<any> {
    if (emailData.type && emailData.type == "batch") {
      delete emailData.type;
      return this.httpService.post(
        environment.BASE_URL + `surveys/${surveyId}/send_email/`,
        emailData
      );
    } else {
      delete emailData.type;
      return this.httpService.post(
        environment.BASE_URL + `surveys/${surveyId}/send_reminder_to_all/`,
        emailData
      );
    }
  }

  public sendProjectReminderToAll(projectId: string, emailData: any): Observable<any> {
    if (emailData.type && emailData.type == "batch") {
      delete emailData.type;
      return this.httpService.post(
        environment.BASE_URL + `projects/${projectId}/schedule/`,
        emailData
      );
    } else {
      delete emailData.type;
      return this.httpService.post(
        environment.BASE_URL + `projects/${projectId}/send-reminder-all/`,
        emailData
      );
    }
  }

  public saveEmail(
    surveyId: string,
    emailData: any,
    isUpdate: boolean
  ): Observable<any> {
    if (isUpdate)
      return this.httpService.put(
        environment.BASE_URL + `surveys/${surveyId}/schedule-initial-emails/`,
        emailData
      );
    else {
      return this.httpService.post(
        environment.BASE_URL + `surveys/${surveyId}/schedule-initial-emails/`,
        emailData
      );
    }
  }

  public sendScheduleReminderToAll(
    surveyId: string,
    emailData: any
  ): Observable<any> {
    if (emailData.type && emailData.type == "batch") {
      // delete emailData.type;
      return this.httpService.post(
        environment.BASE_URL +
          `survey_emails/${emailData.old_survey_email_identifier}/schedule-reminder-emails/`,
        emailData
      );
    } else {
      // delete emailData.type;
      return this.httpService.post(
        environment.BASE_URL + `surveys/${surveyId}/schedule-reminder-emails/`,
        emailData
      );
    }
  }




  public sendMultipleSchedule(
    surveyId: string,
    emailData: any
  ): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + `surveys/${surveyId}/schedule-initial-emails/`,
      emailData
    );
  }

  public downloadContactsSample(surveyType: number): Observable<any> {
    return this.httpService.get(
      environment.BASE_URL + `sample-contacts/?survey_type=${surveyType}`
    );
  }

  addCompany(companyName: any) {
    return this.httpService.post(
      environment.BASE_URL + "companies/",
      companyName
    );
  }
  editCompany(obj: any) {
    return this.httpService.put(`${environment.BASE_URL}companies/${obj.id}/`, {
      name: obj.name,
    });
  }

  deleteCompany(id: any) {
    return this.httpService.delete(`${environment.BASE_URL}companies/${id}/`);
  }

  getCompany(pageNo?: any) {
    let queryStr = pageNo ? `&page=${pageNo}` : "";
    return this.httpService.get(
      environment.BASE_URL + `companies/?ordering=name${queryStr}`
    );
  }

  getCompanyList(queryParams) {

    queryParams['ordering'] = "name"
    return this.httpService.get(
      environment.BASE_URL + `companies/`,{ params:queryParams}
    );
  }

  search(url: string, typee?: string) {
    var filterList = this.httpService.get(`${environment.BASE_URL}${url}`).pipe(
      debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
      map(
        (data: any) => {
          let objArr: any = [];
          if (typee) {
            objArr = [{ name: `Add New ${typee}` }];
          }
          // // this.sharedService.setLoader(false);
          return [...data.results, ...objArr];
          // // return data.results.length? data.results : objArr;
          // return data.results;
        },
        (err: any) => {
          // this.sharedService.setLoader(false);
        }
      )
    );
    return filterList;
  }

  public uploadFile(file: string): Observable<any> {
    return this.httpService.post(environment.BASE_URL + "upload_data/", {
      file,
    });
  }

  public getSurveyTypeByCode(typeCode: number): any {
    switch (typeCode) {
      case 0:
        return "Standard Survey";
        break;

      case 1:
        return "Multirater Survey";
        break;
   
      case 2:
        return "Multirater Campaign";
        break;
      default:
        break;
    }
  }

  checkIfEmailBlackListed(survey_id: any, emailObj: any) {
    return this.httpService.post(
      `${environment.BASE_URL}blacklisted-domain/${survey_id}/`,
      emailObj
    );
  }

  sendSurveyResumeLink(sendObj: any) {
    return this.httpService.post(
      `${environment.BASE_URL}send-survey-email-url/`,
      sendObj
    );
  }

  generateIndividualReport(
    survey_id: any,
    currentResponseId: any,
    individualReportCode: any
  ) {
    return this.httpService.get(
      `${environment.BASE_URL}survey/${survey_id}/resp/${currentResponseId}/individual-report/?code=${individualReportCode}`
    );
  }

  getAttributes(page: any, search: any, currentSurveyId: any) {
    return this.httpService.get(
      `${environment.BASE_URL}surveys/${currentSurveyId}/questionattributes/?page=${page}&search=${search}`
    );
  }

  addAttributes(obj: any, currentSurveyId: any) {
    return this.httpService.post(
      `${environment.BASE_URL}surveys/${currentSurveyId}/questionattributes/`,
      obj
    );
  }
  public getCountryList() {
    return this.httpService.get(
      `${environment.BASE_URL}/surveys/country_list/`
    );
  }
  public checkPhoneIfValid(obj: any) {
    return this.httpService.post(
      `${environment.BASE_URL}/surveys/validate_phone_number/`,
      obj
    );
  }
  public getActivityLogs(
    page_number: any,
    page_size: any,
    order: string,
    obj: any
  ) {
    let queryString = ``;

    if (obj._fromdate && obj._enddate) {
      queryString += `&from_date=${obj._fromdate}&to_date=${obj._enddate}`;
    }
    if (obj.activityType) {
      queryString += `&activity_type=${obj.activityType}`;
    }
    if (obj.user_email) {
      queryString += `&user_email=${encodeURIComponent(obj.user_email)}`;
    }
    if (queryString) {
      return this.httpService.get(
        `${environment.BASE_URL}logs/activities/?ordering=${order}&page=${
          page_number || 1
        }&page_size=${page_size || 20}${queryString}`
      );
    }
    return this.httpService.get(
      `${environment.BASE_URL}logs/activities/?ordering=${order}&&page=${
        page_number || 1
      }&page_size=${page_size || 20}`
    );
  }
  public getSessionLogs(
    page_number: any,
    page_size: any,
    order: string,
    obj: any
  ) {
    let queryString = ``;

    if (obj._fromdate && obj._enddate) {
      queryString += `&from_date=${obj._fromdate}&to_date=${obj._enddate}`;
    }
    if (obj.activityType) {
      queryString += `&activity_type=${obj.activityType}`;
    }
    if (obj.user_email) {
      queryString += `&user_email=${encodeURIComponent(obj.user_email)}`;
    }
    if(obj.log_type){
      queryString += `&log_type=${obj.log_type}`;
    }
    if(obj.log_type){
      queryString += `&log_type=${obj.log_type}`;
    }
    if(obj.role){
      queryString += `&role=${obj.role}`;
    }
    if(obj.status){
      queryString += `&status=${obj.status}`;
    }
    if (queryString) {
      return this.httpService.get(
        `${environment.BASE_URL}logs/sessions/?ordering=${order}&page=${
          page_number || 1
        }&page_size=${page_size || 20}${queryString}`
      );
    }
    return this.httpService.get(
      `${environment.BASE_URL}logs/sessions/?ordering=${order}&page=${
        page_number || 1
      }&page_size=${page_size || 20}`
    );
  }
  public getActivityTypeFilters() {
    return this.httpService.get(`${environment.BASE_URL}logs/essentials/?type`);
  }
  // public getAccountTypeFilters() {
  //   debugger
  //   return this.httpService.get(`${environment.BASE_URL}logs/essentials/?type`);
  // }
  public exportData(type: any) {
    return this.httpService.get(`${environment.BASE_URL}logs/export/${type}/`);
  }

  public exportData1(data: any, type: any, from_date: any, to_date: any) {
    return this.httpService.post(
      `${environment.BASE_URL}logs/export/activities/` +
        `?from_date=${from_date}&to_date=${to_date}/`,
      type
    );
  }
  public exportData2(data: any, type: any, from_date: any, to_date: any) {
    return this.httpService.post(
      `${environment.BASE_URL}logs/export/sessions/` +
        `?from_date=${from_date}&to_date=${to_date}/`,
      type
    );
  }

  public get2faQRUri() {
    return this.httpService.get(`${environment.BASE_URL}mfa/`);
  }

  public postTwoFACode(body: any) {
    return this.httpService.post(environment.BASE_URL + `mfa/`, body);
  }

  public getEmailTemplateList(
    pageObj: Page,
    filter: SurveyFilter,
    sort?: ISurveySort,
    type?: any
  ): Observable<any> {
    let queryStatement = `page=${
      pageObj.pageNumber == 0 ? 1 : pageObj.pageNumber
    }&page_size=${pageObj.size}`;

    if (!sort) {
      queryStatement +=
        filter.ordered_by !== null ? `&ordering=-${filter.ordered_by}` : "";
    } else if (sort) {
      switch (sort.type) {
        case "asc":
          queryStatement += `&ordering=${sort.ordering}`;
          break;

        case "desc":
          queryStatement += `&ordering=-${sort.ordering}`;
          break;

        default:
          break;
      }
    }
    if (type)
      return this.httpService.get(
        environment.BASE_URL +
          `emailtemplate/?type=${type}&deactivated=true&${queryStatement}`
      );
    else {
      return this.httpService.get(
        environment.BASE_URL +
          `emailtemplate/?deactivated=true&${queryStatement}`
      );
    }
  }

  public createNewEmailTemplate(obj: any) {
    return this.httpService.post(`${environment.BASE_URL}emailtemplate/`, obj);
  }

  public editEmailTemplate(obj: any, id: any) {
    return this.httpService.put(
      `${environment.BASE_URL}emailtemplate/${id}/`,
      obj
    );
  }

  public deleteEmailTemplate(id: any) {
    return this.httpService.delete(
      `${environment.BASE_URL}emailtemplate/${id}/`
    );
  }

  public deactivateEmailTemplate(id: any) {
    return this.httpService.put(
      `${environment.BASE_URL}emailtemplate/${id}/deactivate/`,
      {}
    );
  }

  public reactivateEmailTemplate(id: any) {
    return this.httpService.put(
      `${environment.BASE_URL}emailtemplate/${id}/reactivate/`,
      {}
    );
  }

  public getTemplates(type: any, pageNo?: any):any {
    let queryStr = pageNo ? `&page=${pageNo}` : "";
    return this.httpService.get(
      environment.BASE_URL +
        `emailtemplate/?type=${type}&deactivated=false${queryStr}&ordering=created_at`
    );
  }

  public getEmailTemplates(params:HttpParams):Observable<EmailTemplateResponse>{
    let url = `${environment.BASE_URL}emailtemplate/`
    return this.httpService.get(url,{params}) as Observable<EmailTemplateResponse>;
  }

  searchTemplate(url: string) {
    var filterList = this.httpService.get(`${environment.BASE_URL}${url}`).pipe(
      debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
      map(
        (data: any) => {
          return [...data.results];
        },
        (err: any) => {}
      )
    );
    return filterList;
  }

  public saveDisplayLogicCondition(surveyId: any, body: any): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + `survey/${surveyId}/display-logic/`,
      body
    );
  }

  public saveLogic(surveyId: any, quesId: any, body: any): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + `survey/${surveyId}/questions/${quesId}/logic/`,
      body
    );
  }

  public updateLogic(surveyId: any, quesId: any, body: any): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL + `survey/${surveyId}/questions/${quesId}/logic/`,
      body
    );
  }

  public saveSkipLogicCondition(surveyId: any, body: any): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + `survey/${surveyId}/logic/`,
      body
    );
  }

  public updateDisplayLogicCondition(
    surveyId: any,
    body: any,
    displayLogicId: any
  ): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL +
        `survey/${surveyId}/display-logic/${displayLogicId}/`,
      body
    );
  }

  public updateSkipLogicCondition(
    surveyId: any,
    body: any,
    logicId: any
  ): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL + `survey/${surveyId}/logic/${logicId}/`,
      body
    );
  }

  public deleteLogicCondition(surveyId: any, quesId: any, displayLogicId: any) {
    return this.httpService.delete(
      `${environment.BASE_URL}survey/${surveyId}/questions/${quesId}/logic/${displayLogicId}/`
    );
  }

  public deleteSkipLogicCondition(surveyId: any, logicId: any) {
    return this.httpService.delete(
      `${environment.BASE_URL}survey/${surveyId}/logic/${logicId}/`
    );
  }

  ////////////
  //Branching apis
  public saveBranchLogicCondition(surveyId: any, body: any): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + `survey/${surveyId}/logic/`,
      body
    );
  }

  public updateBranchLogicCondition(
    surveyId: any,
    body: any,
    logicId: any
  ): Observable<any> {
    return this.httpService.put(
      environment.BASE_URL + `survey/${surveyId}/logic/${logicId}/`,
      body
    );
  }

  public deleteBranchLogicCondition(surveyId: any, logicId: any) {
    return this.httpService.delete(
      `${environment.BASE_URL}survey/${surveyId}/logic/${logicId}/`
    );
  }

  public getColorPalatteList(surveyId:string){
    return this.httpService.get(
      `${environment.BASE_URL}surveys/${surveyId}/get_color/`
      ).pipe(
        catchError(()=>{
          return of();
        })
      )
  }

  public setColorPalatteList(surveyId:string, colorList:any){
    return this.httpService.put(
      `${environment.BASE_URL}surveys/${surveyId}/update_colors/`, colorList
      );
  }


  public createProject(project: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + "projects/", project);
  }

  public downloadCSVForDataManagement(surveyId:string, type:string,export_type:string) {
    return this.httpService.get(`${environment.BASE_URL}surveys/${surveyId}/csv-report/?type=${type}&export_type=${export_type}`);
  }

  public downloadCSVForDataManagementDisplayed(surveyId:string, type:string,export_type:string, fields:DataManagementColumnFields) {
    return this.httpService.post(`${environment.BASE_URL}surveys/${surveyId}/csv-report/displayed?type=${type}&export_type=${export_type}`, fields);
  }
  public downloadCSVForProjectDataManagement(projectId:string, type:string,export_type:string) {
    return this.httpService.get(`${environment.BASE_URL}projects/${projectId}/csv-report/?type=${type}&export_type=${export_type}`);
  }

  public downloadCSVForProjectDataManagementDisplayed(projectId:string, type:string,export_type:string, fields:DataManagementColumnFields) {
    return this.httpService.post(`${environment.BASE_URL}projects/${projectId}/csv-report/displayed/?type=${type}&export_type=${export_type}`, fields);
  }


  reopenSurvey(responseIdentifier: string, surveyId: string) {
    return this.httpService.put(
      `${environment.BASE_URL}surveys/${surveyId}/responses/${responseIdentifier}/repoen-survey/`,
      {}
    );
  }
}
