import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { HttpService } from "../http/http.service";
import { environment } from "../../../environments/environment";
import { IUserCredentials } from "../../models/user.model";
import { Router } from "@angular/router";
export interface Credentials {
  // Customize received credentials here
  username: string;
  token: string;
}

export interface LoginContext {
  username_email: string;
  password: string;
  remember?: boolean;
}

const credentialsKey = "credentials";
const expiry_key = "_expiry";
const expiry_min = environment.expiry_min;

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService {
  private _credentials!: Credentials | null;
  public session_timeout_Subject: Subject<any> = new Subject();
  public hamburgerClicked$ = new Subject<any>();

  constructor(private httpService: HttpService, private router: Router) {
    const savedCredentials =
      sessionStorage.getItem(credentialsKey) ||
      localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }
  }

  /**
   * Authenticates the user.
   * @return The user credentials.
   */
  login(context: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + "api-token-auth/", {
      username: context.username_email,
      password: context.password,
    });
  }

  /**
   * Authenticates the user: check for multifactor authentication
   * @param context The login parameters.
   * @return The user credentials.
   */
  authenticate(context: any): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + "authenticate/",
      context
    );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.setCredentials();
    return of(true);
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials && !!this.not_expired;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  /**
   * Gets if user was inactive on screen for more than 20 min.
   * @return needs to logout or resume the last session
   */
  get not_expired(): Boolean {
    if (localStorage.getItem(expiry_key)) {
      let localDate = new Date(localStorage.getItem(expiry_key) as string);
      // return localDate.getTime() > Date.now();
      if (localDate.getTime() > Date.now()) {
        this.setExpiryTime("login");
        return true;
      } else {
        return false;
      }
    } else {
      if (
        (sessionStorage.getItem(credentialsKey) ||
          localStorage.getItem(credentialsKey)) &&
        !localStorage.getItem(expiry_key)
      ) {
        this.setExpiryTime("login");
      }
      return true;
    }
  }

  sessionTimeout() {
    this.session_timeout_Subject.next(Math.random());
  }

  setExpiryTime(type?: any) {
    if (type && type == "login") {
      let _date = new Date();
      _date.setMinutes(_date.getMinutes() + expiry_min);
      let expiry_time = _date.toISOString();
      localStorage.setItem(expiry_key, expiry_time);
      return;
    }

    if (localStorage.getItem(expiry_key)) {
      let localDate = new Date(localStorage.getItem(expiry_key) as string);
      if (localDate.getTime() < Date.now()) {
        // this.logout();
        this.sessionTimeout();
      } else {
        let _date = new Date();
        _date.setMinutes(_date.getMinutes() + expiry_min);
        let expiry_time = _date.toISOString();
        localStorage.setItem(expiry_key, expiry_time);
      }
    }
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  public setCredentials(credentials?: Credentials, remember?: boolean) {
    this._credentials = credentials || null;

    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
      this.setExpiryTime("login");
    } else {
      // sessionStorage.removeItem(credentialsKey);
      // localStorage.removeItem(credentialsKey);
      // sessionStorage.removeItem(expiry_key);
      // localStorage.removeItem(expiry_key);

      sessionStorage.clear();
      localStorage.clear();
      localStorage.setItem("-v", environment.deployment_version);
    }
  }

  policyOpted: boolean = true;

  /* Reset password  */
  public resetPassword(email: string): Observable<any> {
    return this.httpService.post(environment.BASE_URL + "forgot-password/", {
      email: email,
    } as any);
  }

  public setNewPassword(obj: any, token: string): Observable<any> {
    return this.httpService.post(
      environment.BASE_URL + `reset-password/${token}/`,
      obj
    );
  }
}
