import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataShareService {
  public hello = "";
  public locationInfo: any = null;
  updateData = new Subject<any>();
  updatePageNumber = new Subject<any>();
  updateTransition = new Subject<any>();
  // { label: "Aguafina Script" },
  fontStyleList: Array<object> = [
    { label: "Abel", value: "abel" },
    { label: "Abril Fatface", value: "abril fatface" },
    { label: "Acme", value: "acme" },
    { label: "Amatic SC", value: "amatic SC" },
    { label: "Amiri", value: "amiri" },
    { label: "Anton", value: "anton" },
    { label: "Archivo Narrow", value: "archivo narrow" },
    { label: "Arial", value: "arial" },
    { label: "Arimo", value: "arimo" },
    { label: "Arvo", value: "arvo" },
    { label: "Asap", value: "asap" },
    { label: "Assistant", value: "assistant" },
    { label: "B612 Mono", value: "b612 mono" },
    { label: "Barlow Condensed", value: "barlow condensed" },
    { label: "Barlow", value: "barlow" },
    { label: "Bitter", value: "bitter" },
    { label: "Cabin", value: "cabin" },
    { label: "Cairo", value: "cairo" },
    { label: "Catamaran", value: "catamaran" },
    { label: "Cinzel", value: "cinzel" },
    { label: "Comfortaa", value: "comfortaa" },
    { label: "Courier New", value: "courier new" },
    { label: "Crete Round", value: "crete round" },
    { label: "Cutive Mono", value: "cutive mono" },
    { label: "Dancing Script", value: "dancing script" },
    { label: "Domine", value: "domine" },
    { label: "Dosis", value: "dosis" },
    { label: "EB Garamond, serif", value: "EB garamond, serif" },
    { label: "Exo", value: "exo" },
    { label: "Fira Sans Condensed", value: "fira sans condensed" },
    { label: "Fira sans", value: "fira sans" },
    { label: "Fjalla One", value: "fjalla one" },
    { label: "Francois One", value: "francois one" },
    { label: "Heebo", value: "heebo" },
    { label: "Hind Siliguri", value: "hind siliguri" },
    { label: "Inconsolata", value: "inconsolata" },
    { label: "Indie Flower", value: "indie flower" },
    { label: "Josefin Sans", value: "josefin sans" },
    { label: "Kameron", value: "kameron" },
    { label: "Kanit", value: "kanit" },
    { label: "Karla", value: "karla" },
    { label: "Lato", value: "lato" },
    { label: "Libre Baskerville", value: "libre baskervilleato" },
    { label: "Libre Franklin", value: "libre franklin" },
    { label: "Lobster", value: "lobster" },
    { label: "Lora", value: "lora" },
    { label: "Mali", value: "mali" },
    { label: "Maven Pro", value: "maven pro" },
    { label: "Merriweather Sans", value: "merriweather sans" },
    { label: "Merriweather", value: "merriweather" },
    { label: "Montserrat", value: "montserrat" },
    { label: "Nanum Gothic", value: "nanum gothic" },
    { label: "News Cycle", value: "news cycle" },
    { label: "Noticia Text", value: "noticia text" },
    { label: "Noto Serif", value: "noto serif" },
    { label: "Nunito Sans", value: "nunito sans" },
    { label: "Nunito", value: "nunito" },
    { label: "Open Sans Condensed", value: "open sans condensed" },
    { label: "Open Sans ", value: "open sans" },
    { label: "Oswald", value: "oswald" },
    { label: "Overpass", value: "overpass" },
    { label: "Oxygen", value: "oxygen" },
    { label: "Pacifico", value: "pacifico" },
    { label: "Permanent Marker", value: "permanent marker" },
    { label: "Play", value: "Play" },
    { label: "Playfair Display", value: "playfair display" },
    { label: "PT Sans Narrow", value: "PT sans narrow" },
    { label: "PT Serif", value: "PT serif" },
    { label: "Questrial", value: "questrial" },
    { label: "Quicksand", value: "quicksand" },
    { label: "Raleway", value: "raleway" },
    { label: "Righteous", value: "righteous" },
    { label: "Roboto Condensed", value: "roboto condensed" },
    { label: "Roboto Mono", value: "roboto mono" },
    { label: "Roboto Slab", value: "roboto slab" },
    { label: "Roboto", value: "roboto" },
    { label: "Ropa Sans", value: "ropa sans" },
    { label: "Rubik Mono One", value: "rubik mono one" },
    { label: "Rubik", value: "rubik" },
    { label: "Saira Semi Condensed", value: "saira semi condensed" },
    { label: "Shadows Into Light", value: "shadows into light" },
    { label: "Signika", value: "signika" },
    { label: "Source Sans Pro", value: "source sans pro" },
    { label: "Source Serif Pro", value: "source serif pro" },
    { label: "Titillium Web", value: "titillium web" },
    { label: "Times New Roman", value: "times new roman" },
    { label: "Trebuchet MS", value: "trebuchet ms" },
    { label: "Ubuntu Condensed", value: "ubuntu condensed" },
    { label: "Varela Round", value: "varela round" },
    { label: "Vollkorn", value: "vollkorn" },
    { label: "Work Sans", value: "work sans" },
    { label: "Yanone Kaffeesatz", value: "yanone kaffeesatz" },
  ];
  constructor() {}
  dateString(val: any, format?: any) {
    let dd = "AM";
    var h = val.split("T")[1].split(".")[0].split(":")[0];
    if (h >= 12) {
      h = val.split("T")[1].split(".")[0].split(":")[0] - 12;
      dd = "PM";
    }
    if (h == 0) {
      h = 12;
    }
    if (format && format == "24hrformat") {
      return (
        val.split("T")[1].split(".")[0].split(":")[0] +
        ":" +
        val.split("T")[1].split(".")[0].split(":")[1]
      );
    }
    let hr = +val.split("T")[1].split(".")[0].split(":")[0] % 12 || 12;
    return hr + ":" + val.split("T")[1].split(".")[0].split(":")[1] + " " + dd;
  }
}
