import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { ClickOutsideModule } from "ng-click-outside";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LoaderComponent } from "./loader/loader.component";
import { AlertComponent } from "./alert/alert.component";

// import { SortablejsModule } from "ngx-sortablejs";
import { EditorModule } from "@tinymce/tinymce-angular";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { EmailComponent } from "./email/email.component";
// import { ShareButtonModule } from "@ngx-share/button";
import { ClipboardModule } from "ngx-clipboard";

import { SampleMultichoiceTplComponent } from "./question-samples/sample-multichoice-tpl/sample-multichoice-tpl.component";
// import { PapaParseModule } from "ngx-papaparse";
import { CustomTimerToastComponent } from "./custom-timer-toast/custom-timer-toast.component";
import { CoreModule } from "../core/core.module";
import { ContactsComponent } from "./contacts/contacts.component";
import { ServerErrorHandlerModule } from "./server-error-handler/server-error-handler.module";
import { CountdownModule } from "ngx-countdown";

import { QuestionsDirectivesModule } from "./question-section/questions-directives/questions-directives.module";
import { ScheduleEmailComponent } from "./schedule-email/schedule-email.component";
import { EmailTemplateComponent } from "./schedule-email/email-template/email-template.component";
import { TimezoneResolve } from "./timezone.resolver";
import { ScheduleMultipleEmailComponent } from "./schedule-multiple-email/schedule-multiple-email.component";
import { MultipleEmailTemplateComponent } from "./schedule-multiple-email/multiple-email-template/multiple-email-template.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { NewRaterModalComponent } from "./email/new-rater/app-new-rater-modal.component";
import { EmailConfirmModalComponent } from "./email/email-confirm-modal/app-email-confirm-modal.component";
import { SurveyReportLogoUploaderComponent } from "./survey-report-logo-uploader/survey-report-logo-uploader.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { ColorPalatteModalComponent } from "./color-palatte-modal/color-palatte-modal.component";
import { ColorPickerModule } from "ngx-color-picker";
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { SurveyDashboardGraphsComponent } from './survey-dashboard-graphs/survey-dashboard-graphs.component';
import { NgCircleProgressModule } from "ng-circle-progress";
import { SurveyTableStatusInfoComponent } from './survey-table-status-info/survey-table-status-info.component';
import { TinymceEditorComponent } from './tinymce-editor/tinymce-editor.component';
import { TemplateListSelectComponent } from './template-list-select/template-list-select.component';
import { ProjectFilterComponent } from './project-filter/project-filter.component';
import { ProjectFilterChipsComponent } from './project-filter-chips/project-filter-chips.component';
import { TablePageCountDisplayComponent } from "./table-page-count-display/table-page-count-display.component";
import { RequiredStarComponent } from './required-star/required-star.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
import { CollapsableCardComponent } from './collapsable-card/collapsable-card.component';
import { SurveyGeneralGuidelinesComponent } from './survey-general-guidelines/survey-general-guidelines.component';
import { ShortcodeSelectComponent } from './shortcode-select/shortcode-select.component';
import { TestEmailModalComponent } from './test-email-modal/test-email-modal.component';
import { AddRecepientModalComponent } from "./add-recepients-modal/add-recepient-modal.component";
import { BulkUploadModalComponent } from "./add-recepients-modal/bulk-upload-modal/bulk-upload-modal.component";
import { UniqueColumnNameCheckModalComponent } from "./add-recepients-modal/unique-column-name-check-modal/unique-column-name-check-modal.component";
import { EmailStatusIndicatorComponent } from './email-status-indicator/email-status-indicator.component';
import { PasswordStrengthComponent } from "./password-strength-meter/password-strength.component";
import { InputMaxLengthDirective } from "./password-strength-meter/max-length.directive";
import { ExportDataButtonComponent } from "./export-data-button/export-data-button.component"
import { ModifyDataButtonComponent } from "../../app/modules/survey/survey-report/data-management/modify-data-button/modify-data-button.component"
import { EditResponseComponent } from "./editResponse/edit-response/edit-response.component";
@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    NgbDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    ShareButtonsModule,
    ShareIconsModule,
    // ShareButtonModule,
    EditorModule,
    ClickOutsideModule,
    NgxDatatableModule,
    ServerErrorHandlerModule,
    // PapaParseModule,
    NgbModule,
    ClipboardModule,
    //SortablejsModule.forRoot({
//      animation: 200,
//    }),
    CountdownModule,
    NgSelectModule,
    QuestionsDirectivesModule,
    ImageCropperModule,
    ColorPickerModule,
    NgCircleProgressModule.forRoot({
      "radius": 30,
      "space": -2,
      "outerStrokeGradient": false,
      "outerStrokeColor": "#fe8a00",
      "outerStrokeGradientStopColor": "#53a9ff",
      "showZeroOuterStroke": false,
      "innerStrokeColor": "#e7e8ea",
      "animateTitle": false,
      "animationDuration": 1000,
      "showSubtitle": false,
      "showBackground": false,
      "clockwise": false,
      "startFromZero": true
    })
    
  ],
  declarations: [
    LoaderComponent,
    AlertComponent,
    EmailComponent,
    ScheduleEmailComponent,
    SampleMultichoiceTplComponent,
    CustomTimerToastComponent,
    ContactsComponent,
    EmailTemplateComponent,
    ScheduleMultipleEmailComponent,
    MultipleEmailTemplateComponent,
    NewRaterModalComponent,
    EmailConfirmModalComponent,
    SurveyReportLogoUploaderComponent,
    ColorPalatteModalComponent,
    SurveyDashboardGraphsComponent,
    SurveyTableStatusInfoComponent,
    TinymceEditorComponent,
    TemplateListSelectComponent,
    ProjectFilterComponent,
    ProjectFilterChipsComponent,
    TablePageCountDisplayComponent,
    RequiredStarComponent,
    ProgressBarComponent,
    ConfirmationModalComponent,
    CollapsableCardComponent,
    SurveyGeneralGuidelinesComponent,
    ShortcodeSelectComponent,
    TestEmailModalComponent,
    AddRecepientModalComponent,
    BulkUploadModalComponent,
    UniqueColumnNameCheckModalComponent,
    EmailStatusIndicatorComponent,
    PasswordStrengthComponent,
    InputMaxLengthDirective,
    ExportDataButtonComponent,
    ModifyDataButtonComponent,
    EditResponseComponent
  ],
  exports: [
    LoaderComponent,
    AlertComponent,
    EmailComponent,
    ScheduleEmailComponent,
    EmailTemplateComponent,
    SampleMultichoiceTplComponent,
    CustomTimerToastComponent,
    ContactsComponent,
    ScheduleMultipleEmailComponent,
    MultipleEmailTemplateComponent,
    SurveyReportLogoUploaderComponent,
    ColorPalatteModalComponent,
    SurveyDashboardGraphsComponent,
    SurveyTableStatusInfoComponent,
    TinymceEditorComponent,
    TemplateListSelectComponent,
    ProjectFilterComponent,
    ProjectFilterChipsComponent,
    EmailConfirmModalComponent,
    NewRaterModalComponent,
    TablePageCountDisplayComponent,
    RequiredStarComponent,
    ProgressBarComponent,
    ConfirmationModalComponent,
    CollapsableCardComponent,
    SurveyGeneralGuidelinesComponent,
    ShortcodeSelectComponent,
    AddRecepientModalComponent,
    NgCircleProgressModule,
    EmailStatusIndicatorComponent,
    PasswordStrengthComponent,
    InputMaxLengthDirective,
    ExportDataButtonComponent,
    ModifyDataButtonComponent,
    EditResponseComponent
  ],
  providers: [TimezoneResolve],
})
export class SharedModule {}
